import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loadingContract: false,
  list: [],
  totalPageContract: null,
  objectSearchContract: {
    page: 0,
    items_per_page: 10,
    tenCongTyBaoHiem: null,
    soHopDongBH: null,
    typeKenhKhaiThac: null,
    typePhuongThucBoiThuong: null,
    tongPhi: null,
    soLuongCapDo: null,
    soLuongNguoiDuocCapBaoHiem: null,
    thoiHanTuNgay: null || [0, 0],
    thoiHanDenNgay: null || [0, 0],
    daDongPhi: null,
  },
  objectSearchContractDefault: {
    page: 0,
    items_per_page: 10,
    tenCongTyBaoHiem: null,
    soHopDongBH: '',
    typeKenhKhaiThac: null,
    typePhuongThucBoiThuong: null,
    tongPhi: null,
    soLuongCapDo: '',
    soLuongNguoiDuocCapBaoHiem: '',
    thoiHanTuNgay: null || [0, 0],
    thoiHanDenNgay: null || [0, 0],
    daDongPhi: null,
  },
  objectSearchPhieuDeNghiDefault: {
    page: 0,
    items_per_page: 10,
    tenCongTyBaoHiem: null,
    soHopDongBH: '',
    typeKenhKhaiThac: null,
    typePhuongThucBoiThuong: null,
    tongPhi: null,
    soLuongCapDo: '',
    soLuongNguoiDuocCapBaoHiem: '',
    thoiHanTuNgay: null || [0, 0],
    thoiHanDenNgay: null || [0, 0],
    daDongPhi: null,
  },
  listSoHDBH: [],
}

export const listContractSlice = createSlice({
  name: 'listContract',
  initialState,
  reducers: {
    // hợp đồng
    getListContract(state, action) {
      state.loadingContract = true
    },
    getListContractSuccess(state, action) {
      state.list = action.payload.results
      state.loadingContract = false
      state.totalPageContract = action.payload.total
    },
    getListContractFailed(state) {
      state.loadingContract = false
      state.list = []
    },
    // object search hợp đồng
    setObjectSearchContract(state, action) {
      state.objectSearchContract = {...state.objectSearchContract, ...action.payload}
    },
    setObjectSearchFilterDefault(state, action) {
      state.objectSearchContractFilter = initialState.objectSearchContractFilter
    },
    setObjectSearchContractNoMerge(state, action) {
      state.objectSearchContract = {...action.payload}
    },
    unmountListContract(state) {
      state.list = []
      state.loadingContract = true
    },
    getListContractByCongTyBH(state, action) {
      state.loadingContract = false
      state.listSoHDBH = []
      state.listSoHDBHHoSoBLT=[]
    },
    getListContractByCongTyBHSuccess(state, action) {
      const data = action.payload.data.results?.map((item) => ({
        label: item.label,
        value: item.id,
      }))
      state.listSoHDBH = data
      state.loadingContract = true
    },
    getListContractByCongTyBHFailed(state) {
      state.listSoHDBH = []
      state.listSoHDBHHoSoBLT=[]
      state.loadingContract = false
    },
    //
  },
})
export const listContractActions = listContractSlice.actions
const listContractReducer = listContractSlice.reducer

export default listContractReducer

export const selectObjectSearchContract = (state) => state.listContract.objectSearchContract
export const selectObjectSearchContractFilter = (state) =>
  state.listContract.objectSearchContractFilter
export const selectObjectSearchContractDefault = (state) =>
  state.listContract.objectSearchContractDefault
export const selectTotalPageContract = (state) => state.listContract.totalPageContract
export const selectLoadingContract = (state) => state.listContract.loadingContract
export const selectListContract = (state) => state.listContract.list
export const selectListSoHDBH = (state) => state.listContract.listSoHDBH
