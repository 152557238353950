import { removeNullFields } from '_metronic/helpers'
import { setLoadingFullPage } from '_metronic/helpers/CommonHelpers'
import ToastMessage from 'app/components/ToastMessage'
import { api } from 'app/config/api'
import axios from 'axios'

export const getListHoSoBLT = (form) => {
  console.log(form, 'form')
  const objectTemp = {
    ...form,
    page: form?.page / form?.items_per_page,
    tinhTrangDuyet:
      Array.isArray(form?.tinhTrangDuyet) && form?.tinhTrangDuyet?.length > 0
        ? form?.tinhTrangDuyet?.map((e) => e.value)
        : null,
    trangThaiDonThuoc:
      Array.isArray(form?.trangThaiDonThuoc) && form?.trangThaiDonThuoc?.length > 0
        ? form?.trangThaiDonThuoc?.map((e) => e.value)
        : null,
    thoiDiemNhanHoSo:
      form?.thoiDiemNhanHoSo?.[0] || form?.thoiDiemNhanHoSo?.[1] ? form.thoiDiemNhanHoSo : null,
    thoiDiemXuLy:
      form?.thoiDiemXuLy?.length === 0 ||
        (Array.isArray(form?.thoiDiemXuLy) &&
          form?.thoiDiemXuLy.includes(true) &&
          form?.thoiDiemXuLy.includes(false))
        ? null // Nếu có cả true và false thì gán null
        : form?.thoiDiemXuLy, // Ngược lại giữ nguyên
    maHopDong: form?.maHopDong ? form?.maHopDong?.value : null,
    trangThaiGuiHoSoLC:
      form?.trangThaiGuiHoSoLC?.length === 0 ||
        (Array.isArray(form?.trangThaiGuiHoSoLC) &&
          form?.trangThaiGuiHoSoLC.includes(0) &&
          form?.trangThaiGuiHoSoLC.includes(1))
        ? null // Nếu có cả true và false thì gán null
        : form?.trangThaiGuiHoSoLC, // Ngược lại giữ nguyên
    idNguoiThamDinh: form?.idNguoiThamDinh?.id ? form?.idNguoiThamDinh?.id : null,
    vip:form?.vip?.value ?? null,
  }
  const formTemp = removeNullFields(objectTemp)
  const objString = '?' + new URLSearchParams(formTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/paginate` + objString)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDetailBLTTabThongTinHoSo = (id) => {
  return new Promise((resolve, reject) => {
    setLoadingFullPage(true)
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => {
        setLoadingFullPage(false)
      })
  })
}

export const getDetailBLTTabQuyenLoiBaoHiem = (data) => {
  const { id, dataChiTiet, dataDonThuoc } = data
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/quyen-loi-bao-lanh-thuoc`)
      .then(({ data }) => {
        let dataConverted
        dataConverted = { ...data }
        const totalThanhTienDonHang =
          Array.isArray(dataDonThuoc) && dataDonThuoc?.length > 0
            ? dataDonThuoc.reduce((sum, item) => sum + (item.thanhTien || 0), 0)
            : 0
        const totalThanhTienPheDuyet =
          Array.isArray(dataDonThuoc) && dataDonThuoc?.length > 0
            ? dataDonThuoc.reduce((sum, item) => sum + (item.tienDuyet || 0), 0)
            : 0
        if (dataChiTiet?.tinhTrangDuyet === 13 && dataChiTiet?.trangThaiDonThuoc === 3) {
          if (dataConverted?.result && dataConverted.result.length > 0) {
            dataConverted.result[0].tienVienPhi = totalThanhTienDonHang
            dataConverted.result[0].tienLanhDaoDuyet = totalThanhTienPheDuyet
          }
        }
        resolve(dataConverted)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const getDetailBLTThongTinThuoc = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/thong-tin-thuoc`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const capNhatHoSoBLT = (result) => {
  const { id, values } = result

  return new Promise((resolve, reject) => {
    setLoadingFullPage(true)
    axios
      .put(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}`, values)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => {
        setLoadingFullPage(false)
      })
  })
}

export const getFileDinhKemHoSoBLT = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/file-dinh-kem`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const getThongTinHoaDonHoSoBLT = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/thong-tin-hoa-don`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const postDuyetBlt = (data) => {
  const { id } = data
  return new Promise((resolve, reject) => {
    setLoadingFullPage(true)
    axios
      .put(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/actions/duyet`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => {
        setLoadingFullPage(false)
      })
  })
}

export const huyDuyetBlt = (value) => {
  const { id, data } = value
  return new Promise((resolve, reject) => {
    setLoadingFullPage(true)
    axios
      .put(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/actions/huy-duyet`, data)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => {
        setLoadingFullPage(false)
      })
  })
}

export const getThongTinTienTrinhXuLyHoSoBLT = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HO_SO_BAO_LANH_THUOC}/${id}/tien-trinh`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const getTongHanMuc = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HAN_MUC_HSTTTT}${id}/han-muc-con-lai?version=2`)
      .then(({ data }) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const duyetHoSoBltNhieu = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(api.API_HO_SO_BAO_LANH_THUOC + '/actions/duyet', { ids: data?.map((val) => val?.id) })
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const guiDonThuocDsHoSoBLT = (data) => {
  const payLoadGuiDonThuoc = data?.map((item) => ({
    idAzinsuClaim: item?.id || null,
    fptId: item?.idFPT || null,
    claimCode: item?.soHoSoBoiThuong || null,
  }))
  return new Promise((resolve, reject) => {
    axios
      .post(api.API_HO_SO_BAO_LANH_THUOC + '/gui-lai-don-thuoc', payLoadGuiDonThuoc)
      .then(({ data }) => {
        resolve({ data })
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const dongBoDonThuocHoSoBLT = (data) => {
  const payLoadDongBoDonThuoc = {
    id: data?.id,
  }
  const formTemp = removeNullFields(payLoadDongBoDonThuoc)
  const objString = '?' + new URLSearchParams(formTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .put(api.API_HO_SO_BAO_LANH_THUOC + `/${data?.id}/actions/dong-bo-don-thuoc`)
      .then(({ data }) => {
        resolve({ data })
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const getSoLuongBanGhiDanhSach = (form) => {
  console.log(form, 'form')
  const objectTemp = {
    ...form,
    page: form?.page / form?.items_per_page,
    tinhTrangDuyet:
      Array.isArray(form?.tinhTrangDuyet) && form?.tinhTrangDuyet?.length > 0
        ? form?.tinhTrangDuyet?.map((e) => e.value)
        : null,
    trangThaiDonThuoc:
      Array.isArray(form?.trangThaiDonThuoc) && form?.trangThaiDonThuoc?.length > 0
        ? form?.trangThaiDonThuoc?.map((e) => e.value)
        : null,
    thoiDiemNhanHoSo:
      form?.thoiDiemNhanHoSo?.[0] || form?.thoiDiemNhanHoSo?.[1] ? form.thoiDiemNhanHoSo : null,
    thoiDiemXuLy:
      form?.thoiDiemXuLy?.length === 0 ||
        (Array.isArray(form?.thoiDiemXuLy) &&
          form?.thoiDiemXuLy.includes(true) &&
          form?.thoiDiemXuLy.includes(false))
        ? null // Nếu có cả true và false thì gán null
        : form?.thoiDiemXuLy, // Ngược lại giữ nguyên
    maHopDong: form?.maHopDong ? form?.maHopDong?.value : null,
    trangThaiGuiHoSoLC:
      form?.trangThaiGuiHoSoLC?.length === 0 ||
        (Array.isArray(form?.trangThaiGuiHoSoLC) &&
          form?.trangThaiGuiHoSoLC.includes(0) &&
          form?.trangThaiGuiHoSoLC.includes(1))
        ? null // Nếu có cả true và false thì gán null
        : form?.trangThaiGuiHoSoLC, // Ngược lại giữ nguyên
    idNguoiThamDinh: form?.idNguoiThamDinh?.id ? form?.idNguoiThamDinh?.id : null,
  }
  const formTemp = removeNullFields(objectTemp)
  const objString = '?' + new URLSearchParams(formTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_HO_SO_BAO_LANH_THUOC + `/statistic` + objString)
      .then(({ data }) => {
        resolve({ data })
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const getDisplayTenTDV = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_USER + `/don-vi/${data}`)
      .then(({ data }) => {
        resolve({ data })
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const xacNhanDoiSoat = (id) => {
  return new Promise((resolve, reject) => {
    axios
      .put(api.API_HO_SO_BAO_LANH_THUOC + `/${id}/actions/xac-nhan-doi-soat`)
      .then(({ data }) => {
        resolve({ data })
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}

export const printFileToTrinhService = (data) => {
  const { id, type } = data
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_HO_SO_BAO_LANH_THUOC + `/${id}/giay-thong-bao-boi-thuong?type=${type}`, {
        responseType: 'blob',
      })
      .then(({ data }) => {
        resolve({ data })
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => { })
  })
}