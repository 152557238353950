import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  ListCSKCB: [],
  listCSKCBNoLabelValue: [],
  ListInsuranceCompany: [],
  listProvince: [],
  idSelectedProvince: null,
  idSelectedDistrict: null,
  listDistrict: [],
  listWard: [],
  listLyDoTuChoi: [],
  listLyDoChuaThanhToan: [],
  listLoaiFile: [],
  listCauHinFilehHopDong: [],
  loading: false,
  loadingCauHinhDuyetHoSo: false,
  listCauHinhDuyetHoSo:[],
  totalPageCauHinhDuyetHoSo:null,
  objectSearchListCauHinhDuyetHoSo: {
    page: 0,
    items_per_page: 10,
  },
  listTenCanBo : [],
  listInsuranceCompanyWithFilter: [],
  filterListInsuranceCompany: {
    page: 0,
    items_per_page: 10,
    search: '',
  },
  totalLengthListInsuranceCompanyWithFilter: null,
  listDanhMucThuoc: [],
  objectSearchListDanhMucThuoc: {
    page: 0,
    items_per_page: 10,
    searchText: null,
  },
  loadingDanhSachDanhMucThuoc: false,
  totalPageDanhSachDanhMucThuoc: null,
  listCauHinhGiaoViec: [],
  totalCauHinhGiaoViec: null,
  objectSearchCauHinhGiaoViec: {
    page: 0,
    items_per_page: 10,
  },
  loadingCauHinhGiaoViec: false,
  listSoHopDongBH: [],
  objectSearchDanhSachHopDong: {
    page: 0,
    items_per_page: 10,
  },
  totalPageDanhSachHopDong: null,
  loadingCauHinhDongChiTra:false,
  objectSearchCauHinhDongChiTra: {
    page: 0,
    items_per_page: 10,
  },
  listCauHinhDongChiTra: [],
  totalPageCauHinhDongChiTra: null,
  listCskcbBaoLanh: [],
  totalCountCskcbAllBaoLanh: null,
  listLyDoPheDuyet: [],
  listVaiTroThongTinLienHe: [],
  listMaBenh: [],
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getListCSKCB(state, action) {
      state.loading = true
    },
    getListCSKCBSuccess(state, action) {
      state.ListCSKCB = action.payload.results
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListCSKCBFailed(state) {
      state.loading = false
      state.ListCSKCB = []
    },
    getListInsuranceCompany(state, action) {
      state.loading = true
    },
    getListInsuranceCompanySuccess(state, action) {
      state.ListInsuranceCompany = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListInsuranceCompanyFailed(state) {
      state.loading = false
      state.ListInsuranceCompany = []
    },
    getListProvince(state) {
      state.loading = true
    },
    getListProvinceSuccess(state, action) {
      state.listProvince = action.payload
    },
    getListProvinceFailed(state) {
      state.loading = false
      state.ListInsuranceCompany = []
    },
    setIdTinhThanh(state, action) {
      state.idSelectedProvince = action.payload
    },
    getListDistrict(state, action) {
      state.loading = true
    },
    getListDistrictSuccess(state, action) {
      state.listDistrict = action.payload
    },
    getListDistrictFailed(state) {
      state.loading = false
      state.listDistrict = []
    },
    setIdQuanHuyen(state, action) {
      state.idSelectedDistrict = action.payload
    },
    getListWard(state, action) {
      state.loading = true
    },
    getListWardSuccess(state, action) {
      state.listWard = action.payload
    },
    getListWardFailed(state) {
      state.loading = false
      state.listWard = []
    },
    unmountLocation(state) {
      state.listDistrict = []
      state.listProvince = []
      state.listWard = []
    },
    getListLyDoTuChoi(state, action) {
      state.loading = true
    },
    getListLyDoTuChoiSuccess(state, action) {
      state.listLyDoTuChoi = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLyDoTuChoiFailed(state) {
      state.loading = false
      state.listLyDoTuChoi = []
    },
    getListLyDoChuaThanhToan(state, action) {
      state.loading = true
    },
    getListLyDoChuaThanhToanSuccess(state, action) {
      state.listLyDoChuaThanhToan = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLyDoChuaThanhToanFailed(state) {
      state.loading = false
      state.listLyDoChuaThanhToan = []
    },
    getListLoaiFile(state, action) {
      state.loading = true
    },
    getListLoaiFileSuccess(state, action) {
      state.listLoaiFile = action.payload.result
      state.loading = false
      state.totalPage = action.payload.total
    },
    getListLoaiFileFailed(state) {
      state.loading = false
      state.listCauHinFilehHopDong = []
    },
    getListCauHinhLoaiFileHD(state, action) {
      state.loading = true
    },
    getListCauHinhLoaiFileHDSuccess(state, action) {
      const arrayCustom = action.payload.result?.map((item) => ({
        label:
          item?.loai === 'GIAYYEUCAUBOITHUONG'
            ? item.ten + ' - ' + 'Giấy yêu cầu bồi thường'
            : item.ten + '-' + item?.loai,
        value: item.id,
        extraData: {...item}, // Dùng dấu ngoặc nhọn để sao chép đối tượng
      }))
      state.listCauHinFilehHopDong = arrayCustom
      state.loading = false
    },
    getListCauHinhLoaiFileHDFailed(state) {
      state.loading = false
      state.listCauHinFilehHopDong = []
    },
    getListCauHinhDuyetHoSo(state, action) {
      state.loadingCauHinhDuyetHoSo = true
    },
    getListCauHinhDuyetHoSoSuccess(state, action) {
      const data = action.payload.results?.map((item) => ({
        ...item, // Giữ nguyên các thuộc tính của `item`
        dsCapDo: item.dsCapDo.map((capDo) => ({
          ...capDo, // Giữ nguyên các thuộc tính của `capDo`
          idCongTyBaoHiem: item.idCongTyBaoHiem, // Thêm `tenCongTyBaoHiem` vào từng `capDo`
        })),
      }));
      state.listCauHinhDuyetHoSo = data
      state.loadingCauHinhDuyetHoSo = false
      state.totalPageCauHinhDuyetHoSo = action.payload.total
    },
    getListCauHinhDuyetHoSoFailed(state) {
      state.loadingCauHinhDuyetHoSo = false
      state.listCauHinhDuyetHoSo = []
    },
    setObjectSearchListCauHinhDuyetHoSo(state, action) {
      state.objectSearchListCauHinhDuyetHoSo = {...state.objectSearchListCauHinhDuyetHoSo, ...action.payload}
    },
    getListTenCanBo(state, action) {
      state.loading = true
    },
    getListTenCanBoSuccess(state, action) {
      state.listTenCanBo = action.payload.result
      state.loading = false
    },
    getListTenCanBoFailed(state) {
      state.loading = false
      state.listTenCanBo = []
    },
    getListInsuranceCompanyWithFilter(state, action) {
      state.loading = true
    },
    getListInsuranceCompanyWithFilterSuccess(state, action) {
      state.listInsuranceCompanyWithFilter = action.payload.results.map((item) => ({
        name: item.label,
        code: item.value,
      }))
      state.totalLengthListInsuranceCompanyWithFilter = action.payload.total
      state.loading = false
    },
    getListInsuranceCompanyWithFilterFailed(state) {
      state.loading = false
      state.listInsuranceCompanyWithFilter = []
    },
    setFilterListInsuranceCompany(state, action) {
      state.filterListInsuranceCompany = {...state.filterListInsuranceCompany, ...action.payload}
    },
    getListDanhMucThuoc(state, action) {
      state.loadingDanhSachDanhMucThuoc = true
    },
    getListDanhMucThuocSuccess(state, action) {
      state.listDanhMucThuoc = action.payload.results
      state.loadingDanhSachDanhMucThuoc = false
      state.totalPageDanhSachDanhMucThuoc = action.payload.total
    },
    getListDanhMucThuocFailed(state) {
      state.loadingDanhSachDanhMucThuoc = false
      state.listDanhMucThuoc = []
    },
    setObjectSearchListDanhMucThuoc(state, action) {
      state.objectSearchListDanhMucThuoc = {
        ...state.objectSearchListDanhMucThuoc,
        ...action.payload,
      }
    },
    getListCauHinhGiaoViec(state, action) {
      state.loadingCauHinhGiaoViec = true
    },
    getListCauHinhGiaoViecSuccess(state, action) {
      state.listCauHinhGiaoViec = action.payload.results
      state.loadingCauHinhGiaoViec = false
      state.totalCauHinhGiaoViec = action.payload.total
    },
    getListCauHinhGiaoViecFailed(state) {
      state.loadingCauHinhGiaoViec = false
      state.listCauHinhGiaoViec = []
    },
    setObjectSearchCauHinhGiaoViec(state, action) {
      state.objectSearchCauHinhGiaoViec = {...state.objectSearchCauHinhGiaoViec, ...action.payload}
    },
    getListSoHopDongBH(state, action) {
      state.loading = true
    },
    getListSoHopDongBHSuccess(state, action) {
      const value = action.payload.results?.map((item) => ({
        value: item.id,
        label: item.label,
      }))
      state.listSoHopDongBH = value
      state.loading = false
      state.totalPageDanhSachHopDong = action.payload.total
    },
    getListSoHopDongBHFailed(state) {
      state.loading = false
      state.listSoHopDongBH = []
    },
    setObjectSearchDanhSachHopDong(state, action) {
      state.objectSearchDanhSachHopDong = {...state.objectSearchDanhSachHopDong, ...action.payload}
    },
    getListCauHinhDongChiTra(state, action) {
      state.loadingCauHinhDongChiTra = true
    },
    getListCauHinhDongChiTraSuccess(state, action) {
      state.listCauHinhDongChiTra = action.payload.result
      state.loadingCauHinhDongChiTra = false
      state.totalPageCauHinhDongChiTra = action.payload.total
    },
    getListCauHinhDongChiTraFailed(state) {
      state.loadingCauHinhDongChiTra = false
      state.listCauHinhDongChiTra = []
    },
    getListCskcbBaoLanh(state, action) {
      state.loading = true
    },
    getListCskcbBaoLanhSuccess(state, action) {
      state.loading = false
      state.listCskcbBaoLanh = action.payload
      state.totalCountCskcbAllBaoLanh = action?.payload?.length
    },
    getListCskcbBaoLanhFailed(state, action) {
      state.loading = false
      state.listCskcbBaoLanh = []
    },
    getListLyDoPheDuyet(state, action) {},
    getListLyDoPheDuyetSuccess(state, action) {
      state.listLyDoPheDuyet = action.payload.result
    },
    getListLyDoPheDuyetFailed(state, action) {
      state.listLyDoPheDuyet = []
    },
    getListVaiTroThongTinLienHeHopDong(state, action) {},
    getListVaiTroThongTinLienHeHopDongSuccess(state, action) {
      const result = action.payload.result
        ?.filter((item) => item.hieuLuc === true)
        .map((item) => {
          return {
            rest: {
              ...item,
            },
            value: item?.id,
            label: item.role,
          }
        })
      state.listVaiTroThongTinLienHe = result
    },
    getListVaiTroThongTinLienHeHopDongFailed(state, action) {
      state.listVaiTroThongTinLienHe = []
    },
    getListMaBenh(state, action) {},
    getListMaBenhSuccess(state, action) {
      state.listMaBenh=action.payload.result
    },
    getListMaBenhFailed(state, action) {},
    // 
    getListCskcbNoLabelValue(state, action) {},
    getListCskcbNoLabelValueSuccess(state, action) {
      state.listCSKCBNoLabelValue=action.payload.result
    },
    getListCskcbNoLabelValuehFailed(state, action) {},
  },
})
export const categoryActions = categorySlice.actions
const categoryReducer = categorySlice.reducer

export default categoryReducer

export const selectListCSKCB = (state) => state.category.ListCSKCB
export const selectListInsuranceCompany = (state) => state.category.ListInsuranceCompany
export const selectListProvince = (state) => state.category.listProvince
export const selectListDistrict = (state) => state.category.listDistrict
export const selectIdTinhThanh = (state) => state.category.idSelectedProvince
export const selectIdQuanHuyen = (state) => state.category.idSelectedDistrict
export const selectListWard = (state) => state.category.listWard
export const selectListLyDoTuChoi = (state) => state.category.listLyDoTuChoi
export const selectListLoaiFile = (state) => state.category.listLoaiFile
export const selectListLyDoChuaThanhToan = (state) => state.category.listLyDoChuaThanhToan
export const selectListCauHinhDuyetHoSo = (state) => state.category.listCauHinhDuyetHoSo
export const selectLoadingCauHinhDuyetHoSo =(state) => state.category.loadingCauHinhDuyetHoSo
export const selectObjectSearchListCauHinhDuyetHoSo = (state) => state.category.objectSearchListCauHinhDuyetHoSo
export const selectTotalPageCauHinhDuyetHoSo = (state) => state.category.totalPageCauHinhDuyetHoSo
export const selectListTenCanBo = (state) => state.category.listTenCanBo
export const selectListInsuranceCompanyWithFilter = (state) =>
  state.category.listInsuranceCompanyWithFilter
export const selectFilterListInsuranceCompany = (state) => state.category.filterListInsuranceCompany
export const selectTotalLengthListInsuranceCompanyWithFilter = (state) =>
  state.category.totalLengthListInsuranceCompanyWithFilter
export const selectListCauHinhFileHD = (state) => state.category.listCauHinFilehHopDong
export const selectListDanhMucThuoc = (state) => state.category.listDanhMucThuoc
export const selectObjectSearchListDanhMucThuoc = (state) => state.category.objectSearchListDanhMucThuoc
export const selectLoadingDanhSachDanhMucThuoc = (state) => state.category.loadingDanhSachDanhMucThuoc
export const selectTotalPageDanhSachDanhMucThuoc= (state) => state.category.totalPageDanhSachDanhMucThuoc
export const selectListCauHinhGiaoViec = (state) => state.category.listCauHinhGiaoViec
export const selectTotalCauHinhGiaoViec = (state) => state.category.totalCauHinhGiaoViec
export const selectObjectSearchCauHinhGiaoViec = (state) =>
  state.category.objectSearchCauHinhGiaoViec
export const selectLoadingCauHinhGiaoViec = (state) => state.category.loadingCauHinhGiaoViec
export const selectListSoHopDongBH = (state) => state.category.listSoHopDongBH
export const selectObjectSearchDanhSachHopDong = (state) =>
  state.category.objectSearchDanhSachHopDong
export const selectTotalPageDanhSachHopDong = (state) => state.category.totalPageDanhSachHopDong
export const selectListCauHinhDongChiTra = (state) => state.category.listCauHinhDongChiTra
export const selectTotalPageCauHinhDongChiTra = (state) => state.category.totalPageCauHinhDongChiTra
export const selectObjectSearchCauHinhDongChiTra = (state) =>
  state.category.objectSearchCauHinhDongChiTra
export const selectLoadingCauHinhDongChiTra = (state) => state.category.loadingCauHinhDongChiTra
export const selectTotalCountCskcbAllBaoLanh = (state) => state.category.totalCountCskcbAllBaoLanh
export const selectListLyDoPheDuyet = (state) => state.category.listLyDoPheDuyet
export const selectListVaiTroThongTinLienHe = (state) => state.category.listVaiTroThongTinLienHe
export const selectListMaBenh = (state) => state.category.listMaBenh
