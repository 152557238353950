import { handleError } from "_metronic/helpers"
import { contractStepSevenActions } from "./contractStepSevenSlice"
import { call, put, takeLatest ,delay } from "redux-saga/effects"
import { getDanhSachCoSo } from "./service"

function* debounceSearchListCoSo(action) {
  yield delay(500) // Đợi 300ms trước khi thực hiện tìm kiếm
  yield call(handleGetListDanhSachCoSo, {payload: action.payload})
}

function* handleGetListDanhSachCoSo({payload}) {
  try {
    const resultDanhSachLoaiKhachHang = yield call(getDanhSachCoSo, payload)
    if (resultDanhSachLoaiKhachHang) {
      yield put({
        type: contractStepSevenActions.getListDanhSachCoSoSuccess.type,
        payload: resultDanhSachLoaiKhachHang,
      })
    }
  } catch (e) {
    yield put({
      type: contractStepSevenActions.getListDanhSachCoSoFailed.type,
    })
    handleError(e)
  }
}

export default function* contractStepSevenSaga() {
      yield takeLatest(contractStepSevenActions.getListDanhSachCoSo.type, debounceSearchListCoSo)
    
}
