import { DetailHoSoTTTTDTO } from "../model/GetDetailHoSoTTTT";
import { PostPutHoSoTTTT } from "../model/PostPutHoSoTTTT";


function convertToPostPutHoSoTTTT(dto: DetailHoSoTTTTDTO): PostPutHoSoTTTT {
    return {
        hoTen: dto?.hoTen || "",
        ngaySinh: dto?.ngaySinh,
        soDienThoai: dto?.soDienThoai,
        soCCCDCMT: dto?.soCCCDCMT,
        diaChi: dto?.diaChi,
        gioiTinh: dto?.gioiTinh,
        maTheBHXHBHYT: dto?.maTheBHXHBHYT,
        idDonViBHSK: dto?.idDonViBHSK,
        maTheBHSK: dto?.maTheBHSK,
        idDonViThamDinh: dto?.idDonViThamDinh,
        maTheBHSKMoBile:dto?.maTheBHSKMoBile,
        maBenhNhan: dto?.maBenhNhan,
        nguoiThuHuong: dto?.nguoiThuHuong,
        maCSKCB: dto?.maCSKCB,
        idBenhChinh: dto?.idBenhChinh,
        idMaLoaiKCB: dto?.idMaLoaiKCB,
        ngayKham: dto?.ngayKham,
        lyDoDenKham: dto?.lyDoDenKham,
        email: dto?.email,
        soTaiKhoan: dto?.soTaiKhoan,
        nganHangId: dto?.nganHangId,
        tenNganHang: dto?.tenNganHang,
        ngayVaoVien: dto?.ngayVaoVien,
        ngayRaVien: dto?.ngayRaVien,
        tenBacSiKham: dto?.tenBacSiKham,
        tenBacSiChiDinh: dto?.tenBacSiChiDinh,
        ketQuaDieuTri: dto?.ketQuaDieuTri,
        chuanDoanVien: dto?.chuanDoanVien,
        dsBenhPhu: dto?.dsBenhPhu, 
        dsQuyenLoiHoSo: dto?.dsQuyenLoiHoSo, 
        dsFileDinhKem: dto?.dsFileDinhKem,
        dsChiPhi:dto?.dsChiPhi,
        dsThongTinHoaDon:dto?.dsThongTinHoaDon || null,
        vip: dto?.vip,
        tenCongTyDuocBH: dto?.tenCongTyDuocBH,
        hoTenNguoiLienHe:dto?.hoTenNguoiLienHe,
        soDienThoaiNguoiLienHe:dto?.soDienThoaiNguoiLienHe,
        ngaySinhNguoiLienHe:dto?.ngaySinhNguoiLienHe,
        tongVienPhiBanDau:dto?.tongVienPhiBanDau,
        tyLeThuongTat:dto?.tyLeThuongTat,
        emailNhanThongTin: dto?.emailNhanThongTin,
        ghiChuChiPhi:dto?.ghiChuChiPhi,
        idLyDoTuChoiThanhToan:dto?.idLyDoTuChoiThanhToan,
        loaiHoSoHoanTra:dto?.loaiHoSoHoanTra,
        ghiChuCSKCB:dto?.ghiChuCSKCB,
        idCongTyDoanhNghiep: dto?.idCongTyDoanhNghiep,
        mucLuong : dto.mucLuong ,
        idCauHinhDongChiTra:dto?.idCauHinhDongChiTra,
        // dsCoSoBanLeThuoc:dto?.dsCoSoBanLeThuoc,
    };
}

export { convertToPostPutHoSoTTTT };
