import {FC, Suspense, lazy} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {PREFIX} from 'app/config/router-path'
import {MasterLayout} from '_metronic/layout/MasterLayout'

const PrivateRoutes = () => {
  const CategoryPage = lazy(() => import('app/modules/category'))
  const AccountPage = lazy(() => import('app/modules/accounts'))
  const PayPage = lazy(() => import('app/modules/payment'))
  const SystemPage = lazy(() => import('app/modules/system'))
  const UtilitiesPage = lazy(() => import('app/modules/utilities'))
  const StatisticPage = lazy(() => import('app/modules/statistic'))
  const ProactiveAppraisalPage = lazy(() => import('app/modules/proactive-appraisal'))
  const ContractPage = lazy(() => import('app/modules/contract-customer'))
  const AutomatedAppraisalPage = lazy(() => import('app/modules/automated-appraisal'))
  const QuyetToanPage = lazy(() => import('app/modules/quyet-toan/QuyetToanPage'))
  const TraCuuPage = lazy(() => import('app/modules/tra-cuu'))
  // const QuyetToanPage = lazy(() => import('app/modules/quyet-toan/quyetToanPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/apps/account/overview' />} />

        <Route
          path={PREFIX.DANH_MUC + '/*'}
          element={
            <SuspensedView>
              <CategoryPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.THAM_DINH_CHU_DONG + '/*'}
          element={
            <SuspensedView>
              <ProactiveAppraisalPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.HOP_DONG + '/*'}
          element={
            <SuspensedView>
              <ContractPage />
            </SuspensedView>
          }
        />

        <Route
          path='apps/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.THANH_TOAN + '/*'}
          element={
            <SuspensedView>
              <PayPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.QUAN_TRI_HE_THONG + '/*'}
          element={
            <SuspensedView>
              <SystemPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.TIEN_ICH + '/*'}
          element={
            <SuspensedView>
              <UtilitiesPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.BAOCAO + '/*'}
          element={
            <SuspensedView>
              <StatisticPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.THAM_DINH_TU_DONG + '/*'}
          element={
            <SuspensedView>
              <AutomatedAppraisalPage />
            </SuspensedView>
          }
        />

        <Route
          path={PREFIX.QUYET_TOAN + '/*'}
          element={
            <SuspensedView>
              <QuyetToanPage />
              </SuspensedView>  
          }
        />
        <Route
          path={PREFIX.TRA_CUU + '/*'}
          element={
            <SuspensedView>
              <TraCuuPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/apps/account/overview' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
