import {setLoadingFullPage} from '_metronic/helpers/CommonHelpers'
import {api} from 'app/config/api'
import axios from 'axios'
import {object} from 'yup'

function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0) {
      delete obj[key]
    }
  }
}

export const getDanhSachCoSo = (data) => {
  const {id, objectSearch} = data
  const objectTemp = {
    page: objectSearch?.page / objectSearch?.items_per_page,
    items_per_page: objectSearch?.items_per_page,
  }
  const bodyPost = {
    ma: objectSearch?.ma ? objectSearch?.ma : null,
    ten: objectSearch?.ten ? objectSearch?.ten : null,
    blLoaiTruString:
      Array.isArray(objectSearch?.blLoaiTruString) && objectSearch?.blLoaiTruString?.length > 0
        ? objectSearch?.blLoaiTruString?.map((item) => item?.value)
        : null,
    phanLoaiCoSo:
      Array.isArray(objectSearch?.phanLoaiCoSo) && objectSearch?.phanLoaiCoSo?.length > 0
        ? objectSearch?.phanLoaiCoSo?.map((item) => item?.value)
        : null,
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  // setLoadingFullPage(true)
  return new Promise((resolve, reject) => {
    axios
      .post(api.API_CONTRACT + `/${id}/ds-co-so/paginate` + objString, bodyPost)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
      .finally(() => {
        // setLoadingFullPage(false)
      })
  })
}
