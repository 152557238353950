import {FileDinhKem} from '../model/FileDinhKem'
import {DetailHoSoTTTTDTO} from '../model/GetDetailHoSoTTTT'
import {PostPutHoSoTTTT} from '../model/PostPutHoSoTTTT'
import {convertToPostPutHoSoTTTT} from './convertToPostPutHoSoTTTT'
import {fromZonedTime} from 'date-fns-tz'

export const commonConvertValueFormik = (values: DetailHoSoTTTTDTO) => {
  if (values?.selectedMaBenhPhu) {
    const resultValue: any[] = values?.selectedMaBenhPhu?.map((item: any) => ({
      ten: item.ten,
      ma: item.label,
      id: item.value,
    }))
    values.dsBenhPhu = resultValue
  }
  const data: PostPutHoSoTTTT = convertToPostPutHoSoTTTT(values)
  if (values?.idCauHinhDongChiTra?.value) {
    data.idCauHinhDongChiTra = values?.idCauHinhDongChiTra?.value
  }
  if (values?.ngaySinhFormat !== '' && values?.ngaySinhFormat !== null) {
    data.ngaySinh = new Date(values?.ngaySinhFormat)?.getTime()
  }
  if (values?.ngayKhamFormat !== '' && values?.ngayKhamFormat !== null) {
    data.ngayKham = values?.ngayKhamFormat?.getTime()
  }
  if (values?.ngayVaoVienFormat !== '' && values?.ngayVaoVienFormat !== null) {
    data.ngayVaoVien = new Date(values?.ngayVaoVienFormat)?.getTime()
  }
  if (values?.ngayXayRaTaiNanFormat !== '' && values?.ngayXayRaTaiNanFormat !== null) {
    data.ngayXayRaTaiNan = new Date(values?.ngayXayRaTaiNanFormat)?.getTime()
  } else if (values?.ngayXayRaTaiNan === 0 || values?.ngayXayRaTaiNan === null) {
    data.ngayXayRaTaiNan = null
  }
  if (values?.ngayNhanHoSoThucTeFormat && values?.ngayNhanHoSoThucTeFormat !== null) {
    data.ngayNhanHoSoThucTe = new Date(values?.ngayNhanHoSoThucTeFormat)?.getTime()
  } else if (values?.ngayNhanHoSoThucTe === 0 || values?.ngayNhanHoSoThucTe === null) {
    data.ngayNhanHoSoThucTe = null
  }
  if (
    values?.ngayRaVienFormat !== '' &&
    values?.ngayRaVienFormat !== null &&
    (values?.ngayRaVien !== null || values?.ngayRaVien !== 0)
  ) {
    data.ngayRaVien = new Date(values?.ngayRaVienFormat)?.getTime()
  } else if (
    values?.ngayRaVien === null ||
    values?.ngayRaVien === 0 ||
    values?.ngayRaVienFormat === null
  ) {
    data.ngayRaVien = null
  }
  if (values?.ngaySinhNguoiLienHeFormat !== '' && values?.ngaySinhNguoiLienHeFormat !== null) {
    data.ngaySinhNguoiLienHe = new Date(values?.ngaySinhNguoiLienHeFormat)?.getTime()
  } else if (values?.ngaySinhNguoiLienHe === 0 || values?.ngaySinhNguoiLienHeFormat == null) {
    data.ngaySinhNguoiLienHe = null
  }
  const convertQuyenLoi: any =
    values.dsQuyenLoiHoSo?.map((item: any) => ({
      ...item,
      dsIdLyDoTuChoi:
        Array.isArray(item.dsIdLyDoTuChoi) &&
        item.dsIdLyDoTuChoi.every(
          (subItem: any) => subItem.label !== undefined && subItem.value !== undefined
        )
          ? item.dsIdLyDoTuChoi.map((subItem: any) => subItem.value) // Chỉ ánh xạ value nếu là label-value
          : item.dsIdLyDoTuChoi, // Giữ nguyên nếu không phải là dạng label-value
    })) || [] // Trả về mảng rỗng nếu dsQuyenLoiHoSo không tồn tại

  const convertedDsFileDinhKem: any = values.dsFileDinhKem
    ?.filter((fileDinhKem: FileDinhKem) => fileDinhKem.idDMLoaiFile !== null)
    .map((fileDinhKem: FileDinhKem) => ({
      id: fileDinhKem.id,
      ten: fileDinhKem.ten,
      url: fileDinhKem.url,
      extension: fileDinhKem.extension,
      idDMLoaiFile: fileDinhKem.idDMLoaiFile,
      ghiChu: fileDinhKem.ghiChu,
      ngayNhanBoSung: fileDinhKem.ngayNhanBoSung,
      tenDMLoaiFile: fileDinhKem.tenDMLoaiFile,
    }))
  data.dsFileDinhKem = convertedDsFileDinhKem

  data.dsQuyenLoiHoSo = convertQuyenLoi

  const updatedData: any = values?.dsCoSoBanLeThuoc?.map(({value, label, ...rest}) => ({
    ...rest,
    tenCskcb: label, // Đổi tên `label` thành `tenCskcb`
  }))
  data.dsCoSoBanLeThuoc = updatedData

  return data
}
