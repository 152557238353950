import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loadingListInfoChangeInsuredPerson: false,
  listInfoChangeInsuredPerson: [],
  objectSearchInfoChangeInsuredPerson: {
    page: 0,
    items_per_page: 10,
  },
  objectSearchInfoChangeInsuredPersonFilter: {
    page: 0,
    items_per_page: 10,
    idHopDong: null,
    capDo: null,
  },
  objectSearchInfoChangeInsuredPersonDefault: {
    page: 0,
    items_per_page: 10,
    idHopDong: null,
    capDo: null,
  },
  totalPageInfoChangeInsuredPerson: null,
  listSoHDBH: [],
  listCapDoHanMuc: [],
  listSoHDBHHoSoBLT:[]

}

export const infoChangeInsuredPersonSlice = createSlice({
  name: 'infoChangeInsuredPerson',
  initialState,
  reducers: {
    getListInfoChangeInsuredPerson(state, action) {
      state.loadingListInfoChangeInsuredPerson = true
    },
    getListInfoChangeInsuredPersonSuccess(state, action) {
      state.loadingListInfoChangeInsuredPerson = false
      state.listInfoChangeInsuredPerson = action.payload.results
      state.totalPageInfoChangeInsuredPerson = action.payload.total
    },
    getListInfoChangeInsuredPersonFailed(state, action) {
      state.loadingListInfoChangeInsuredPerson = false
    },
    setObjectSearchInfoChangeInsuredPerson(state, action) {
      state.objectSearchInfoChangeInsuredPerson = {
        ...state.objectSearchInfoChangeInsuredPerson,
        ...action.payload,
      }
      state.objectSearchInfoChangeInsuredPersonFilter = {
        ...state.objectSearchInfoChangeInsuredPersonDefault,
        ...action.payload,
      }
    },
    getListContractByCongTyBH(state, action) {
      state.listSoHDBH = []
      state.listSoHDBHHoSoBLT=[]
    },
    getListContractByCongTyBHSuccess(state, action) {
      console.log(action, 'action')
      const data = action.payload.data.results?.map((item) => ({
        label: item.label,
        value: item.id,
      }))
      state.listSoHDBHHoSoBLT=action.payload.data.results
      state.listSoHDBH = data
    },
    getListContractByCongTyBHFailed(state) {
      state.listSoHDBH = []
      state.listSoHDBHHoSoBLT=[]

    },
    getListCapDoHanMuc(state, action) {
      state.listCapDoHanMuc = []
    },
    getListCapDoHanMucSuccess(state, action) {
      console.log(action, 'action')
      const data = action.payload.result?.map((item) => ({
        label: `Cấp ` + item.capDo,
        value: item.capDo,
      }))
      state.listCapDoHanMuc = data
    },
    getListCapDoHanMucFailed(state) {
      state.listCapDoHanMuc = []
    },
    setObjectSearchInfoChangeInsuredPersonDefault(state) {
      state.objectSearchInfoChangeInsuredPersonFilter =
        initialState.objectSearchInfoChangeInsuredPersonFilter
      state.objectSearchInfoChangeInsuredPerson = initialState.objectSearchInfoChangeInsuredPerson
    },
    setObjectSearchInfoChangeInsuredPersonFilter(state, action) {
      state.objectSearchInfoChangeInsuredPersonFilter = {
        ...state.objectSearchInfoChangeInsuredPersonFilter,
        ...action.payload,
      }
    },
  },
})
export const infoChangeInsuredPersonActions = infoChangeInsuredPersonSlice.actions
const infoChangeInsuredPersonReducer = infoChangeInsuredPersonSlice.reducer

export default infoChangeInsuredPersonReducer
export const selectListInfoChangeInsuredPerson = (state) =>
  state.infoChangeInsuredPerson.listInfoChangeInsuredPerson
export const selectObjectSearchInfoChangeInsuredPerson = (state) =>
  state.infoChangeInsuredPerson.objectSearchInfoChangeInsuredPerson
export const selectLoadingInfoChangeInsuredPerson = (state) =>
  state.infoChangeInsuredPerson.loadingListInfoChangeInsuredPerson
export const selectTotalPageInfoChangeInsuredPerson = (state) =>
  state.infoChangeInsuredPerson.totalPageInfoChangeInsuredPerson
export const selectListSoHDBH = (state) => state.infoChangeInsuredPerson.listSoHDBH
export const selectListCapDoHanMuc = (state) => state.infoChangeInsuredPerson.listCapDoHanMuc
export const selectObjectSearchInfoChangeInsuredPersonDefault = (state) =>
  state.infoChangeInsuredPerson.objectSearchInfoChangeInsuredPersonDefault
export const selectObjectSearchInfoChangeInsuredPersonFilter = (state) =>
  state.infoChangeInsuredPerson.objectSearchInfoChangeInsuredPersonFilter
