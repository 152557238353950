import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  feedbackHistory: [],
  emailLabel: [],
  ocrChangeHistory: [],
  loading: false,
  error: null,
}

const feedbackHistorySlice = createSlice({
  name: 'feedbackHistory',
  initialState,
  reducers: {
    getOcrStatus(state, action) {
      // This action is just for saga to listen to
    },
    getOcrStatusRequest(state) {
      state.loading = true
    },
    getOcrStatusSuccess(state, action) {
      state.loading = false
      // Ensure feedbackHistory is always an array
      state.feedbackHistory = Array.isArray(action.payload) ? action.payload : []
      state.error = null
    },
    getOcrStatusError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    getChangeEmailLabel(state, action) {
      // This is just for saga to listen to
    },
    getChangeEmailLabelRequest(state) {
      state.loading = true
    },
    getChangeEmailLabelSuccess(state, action) {
      state.loading = false
      state.emailLabel = Array.isArray(action.payload) ? action.payload : []
    },
    getChangeEmailLabelError(state, action) {
      state.loading = false
      state.error = action.payload
    },
    getOcrChangeHistory(state, action) {
      // This is just for saga to listen to
    },
    getOcrChangeHistoryRequest(state) {
      state.loading = true
    },
    getOcrChangeHistorySuccess(state, action) {
      state.loading = false
      state.ocrChangeHistory = Array.isArray(action.payload) ? action.payload : []
    },
    getOcrChangeHistoryError(state, action) {
      state.loading = false
      state.error = action.payload
    },
  },
})

export const feedbackHistoryAction = feedbackHistorySlice.actions
export default feedbackHistorySlice.reducer

export const selectOcrStatus = (state) => state.feedbackHistory.feedbackHistory || []
export const selectOcrStatusLoading = (state) => state.feedbackHistory.loading
export const selectOcrStatusError = (state) => state.feedbackHistory.error

export const selectChangeEmailLabel = (state) => state.feedbackHistory.emailLabel || []
export const selectChangeEmailLabelLoading = (state) => state.feedbackHistory.loading
export const selectChangeEmailLabelError = (state) => state.feedbackHistory.error

export const selectOcrChangeHistory = (state) => state.feedbackHistory.ocrChangeHistory || []
export const selectOcrChangeHistoryLoading = (state) => state.feedbackHistory.loading
export const selectOcrChangeHistoryError = (state) => state.feedbackHistory.error