import { combineReducers } from "redux";
import commonReducer from "./common/commonSlice";
import searchFilterReducer from "../modules/proactive-appraisal/redux/searchFilter/searchFilterSlice";
import categoryReducer from "../modules/category/redux/category/categorySlice";
import permissionsReducer from "./permissions/permissionsSlice";
import contractReducer from "../modules/contract-customer/redux/contract/contractSlice";
import listContractReducer from "../modules/contract-customer/redux/listContract/listContractSlice";
import contractStepThirdReducer from "../modules/contract-customer/redux/contractStepThird/contractStepThirdSlice";
import contractStepTwoReducer from "../modules/contract-customer/redux/contractStepTwo/contractStepTwoSlice";
import contractStepOneReducer from "../modules/contract-customer/redux/contractStepOne/contractStepOneSlice";
import manualAssessmentReducer from "../modules/proactive-appraisal/redux/manualAssessment/manualAssessmentSlice";
import phieuDeNghiReducer from "app/modules/payment/redux/phieuDeNghi/phieuDeNghiSlice";
import danhSachDoiSoatReducer from "app/modules/quyet-toan/redux/danhSachDoiSoat/danhSachDoiSoatSlice";
import traCuuReducer from "app/modules/tra-cuu/redux/traCuu/traCuuSlice";
import dashboardReducer from "app/modules/statistic/redux/dashboard/dashboardSlice";
import manageNewsEventsReducer from "app/modules/system/redux/manage-news-events/slice";
import mailboxReducer from "app/modules/system/redux/mailbox/mailboxSlice";
import infoChangeInsuredPersonReducer from "app/modules/contract-customer/redux/infoChangeInsuredPerson/infoChangeInsuredPersonSlice";
import hoSoBLTReducer from "app/modules/proactive-appraisal/pages/ho-so-bao-lanh-thuoc/quan-ly-ho-so-bao-lanh-thuoc/redux/hoSoBLTSlice";
import contractStepSevenReducer from 'app/modules/contract-customer/redux/contractStepSeven/contractStepSevenSlice'
import feedbackHistoryReducer from "app/modules/system/redux/feedback/feedbackSlice";
import lichSuKcbReducer from "app/modules/proactive-appraisal/redux/lichSuKcb/lichSuKcbSlice";

const rootReducer = combineReducers({
  common: commonReducer,
  searchFilter: searchFilterReducer,
  category: categoryReducer,
  permissions: permissionsReducer,
  contract: contractReducer,
  listContract: listContractReducer,
  contractStepThird:contractStepThirdReducer,
  contractStepTwo:contractStepTwoReducer,
  contractStepOne:contractStepOneReducer,
  manualAssessment : manualAssessmentReducer,
  phieuDeNghi:phieuDeNghiReducer,
  danhSachDoiSoat:danhSachDoiSoatReducer,
  traCuu: traCuuReducer,
  dashboard: dashboardReducer,
  manageNewsEvents: manageNewsEventsReducer,
  mailbox: mailboxReducer,
  infoChangeInsuredPerson:infoChangeInsuredPersonReducer,
  hoSoBLT : hoSoBLTReducer,
  contractStepSeven: contractStepSevenReducer,
  feedbackHistory: feedbackHistoryReducer,
  lichSuKcb:lichSuKcbReducer,
});
export default rootReducer;
 
