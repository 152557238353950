import {api} from 'app/config/api'
import axios from 'axios'

export const getDanhSachNguoiDuocBaoHiem = (data) => {
  const value = data?.data
  const objectTemp = {
    ...value,
    page: value?.page / value?.items_per_page,
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CONTRACT + `/${data?.idHopDong}/nguoi-duoc-bao-hiem` + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0) {
      delete obj[key]
    }
  }
}
export const getLoaiKhachHang = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CUSTOMER_TYPE)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getDSNganHang = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_BANK_CATEGORY)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDSLoaiNguoiDuocBH = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_INSURED_PERSON_CATEGORY)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDanhSachCauHinhDongChiTra = (data) => {
  console.log(data , 'service cau hinh dong chi tra');
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CONTRACT + `/${data}/cau-hinh-dong-chi-tra`)
      .then((results) => {
        resolve(results)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDanhSachTenCongTyTapDoan = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DOANH_NGHIEP_ALL + `/${data}/get-cong-ty-tap-doan`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDanhSachTenCongTyThanhVien = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DOANH_NGHIEP_ALL + `/${data}/get-cong-ty-thanh-vien`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}