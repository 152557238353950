import { Api } from '@mui/icons-material'
import {api} from 'app/config/api'
import axios from 'axios'

export const getListInfoChangeInsuredPersonActions = (data) => {
  // console.log('data ds co so kcb bao lanh', data)
  const objectTemp = {
    page: data?.page / data?.items_per_page,
    items_per_page:data?.items_per_page
  }
  const objectPayload = {
    idHopDong : data?.idHopDong?.value,
    capDo : data?.capDo?.value,
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .post(api.API_CONTRACT + `/nguoi-duoc-bao-hiem/logs` + objString, objectPayload)
      .then(({data}) => {
        console.log('data', data)
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (obj[key] === null || obj[key] === 'null' || obj[key] === '' || obj[key]?.length === 0) {
      delete obj[key]
    }
  }
}

export const functionGetListContractByCongTyBH = (data) => {
  const { ...otherData} = data
  const objectTemp = {
    ...otherData,
    page: 0,
    items_per_page: 100000,
    idHopDong : data?.idHopDong?.value,
    capDo: data?.capDo?.value
  }
  const params = `?page=0&items_per_page=1000&idDonViQuanLy=${data}`
  console.log(data,'dffdfdfd');
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DANH_SACH_SO_HOP_DONG_BH_FILTER_THEO_CONG_TY_BAO_HIEM +params)
      .then((results) => {
        resolve(results)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const functionGetListCapDoHanMuc = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CONTRACT + `/${data}/cap-do-han-muc`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
