import { api } from 'app/config/api'
import axios from 'axios'

const header =
{
  headers: {
    'x-token-ocr': '123'
  }
}

export const getListEmail = (payload) => {
  const payloadTemp = {
    ...payload,
    page: (payload?.page + 10) / payload?.limit,
  } 
  const objString = '?' + new URLSearchParams(payloadTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_OCR}/api/v1/email/fetch${objString}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const downloadListEmail = (payload) => {
  const objString = '?' + new URLSearchParams(payload).toString()
  axios
    .get(`${api.API_OCR}/api/v1/email/fetch${objString}&download=1`, { responseType: 'blob' })
    .then(({ data }) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      })
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `list_email.xlsx`
      document.body.appendChild(link)
      link.click()
      URL.revokeObjectURL(url)
      document.body.removeChild(link)
    })
    .catch((err) => {
    })
}

export const getDetailEmail = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_OCR}/api/v1/email/fetch?filter=message_id_hash:${payload}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDetailAttachments = (payload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_OCR}/api/v1/email/get-attachments?email_id=${payload}`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
