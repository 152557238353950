import {createSlice} from '@reduxjs/toolkit'
import {v4 as uuid} from 'uuid'

const initialState = {
  listHoSoBLT: [],
  loadingListhoSoBLT: false,
  chiTietHoSoBLT: null,
  loadingChiTietHoSoBLT: false,
  totalListHoSoBLT: null,
  listChiTietBLTTabThongTinHoSo: [],
  listChiTietBLTTabQuyenLoiBaoHiem: [],
  listChiTietBLTTabQuyenLoiBaoHiemWithoutGroup: [],
  loadingChiTietBLTTabQuyenLoiBaoHiem: false,
  dsThongTinThuoc: [],
  listFileDinhKemBLT: [],
  loadingDsThuoc: false,
  dsThongTinHoaDon: [],
  dsThongTinHoaDonTemp: [],
  loadingDsThongTinHoaDon: false,
  listChiTietBLTTabTienTrinhXuLy: [],
  isPopupOpenCanhBaoHoSoBlt: false,
  dataLoiCanhBaoHoSoBlt: [],
  listTongHanMuc: [],
  objectSearchGetListPaginateThuoc: {
    page: 0,
    items_per_page: 10,
    soHoSoBaoLanhThuoc: null,
    soHoSoBoiThuong: null,
    maDonHangDoiTac: null,
    hoTen: null,
    thoiDiemNhanHoSo: [],
    tinhTrangDuyet: [
      {value: 2, label: 'Đã thẩm định chủ động'},
      {value: 3, label: 'Đã đề nghị duyệt'},
      {value: 4, label: 'Bị trả lại'},
      {value: 6, label: 'Hủy duyệt'},
      {value: 11, label: 'Chờ bổ sung chứng từ'},
      {value: 12, label: 'Đã bổ sung chứng từ'},
      {value: 13, label: 'Đang thẩm định chủ động'},
    ],
    trangThaiDonThuoc: [{value: 3, label: 'Đang thẩm định'}],
    thoiDiemXuLy: [],
    idFPT: null,
    maHopDong: null,
    soCCCD: null,
    findQuaHan: null,
    trangThaiGuiDonThuoc: null,
    idNguoiThamDinh: null,
    trangThaiGuiHoSoLC: null,
    vip: {label: 'Tất cả', value: null},
  },
  objectSearchGetListPaginateThuocFilter: {
    page: 0,
    items_per_page: 10,
    soHoSoBaoLanhThuoc: null,
    soHoSoBoiThuong: null,
    maDonHangDoiTac: null,
    hoTen: null,
    thoiDiemNhanHoSo: null,
    tinhTrangDuyet: [],
    trangThaiDonThuoc: [{value: 3, label: 'Đang thẩm định'}],
    findQuaHan: null,
    idFPT: null,
    maHopDong: null,
    soCCCD: null,
    trangThaiGuiDonThuoc: null,
    idNguoiThamDinh: null,
    trangThaiGuiHoSoLC: null,
    vip: {label: 'Tất cả', value: null},
  },
  objectSearchGetListPaginateThuocDefault: {
    page: 0,
    items_per_page: 10,
    soHoSoBaoLanhThuoc: null,
    soHoSoBoiThuong: null,
    maDonHangDoiTac: null,
    hoTen: null,
    thoiDiemNhanHoSo: [],
    tinhTrangDuyet: [],
    trangThaiDonThuoc: [],
    findQuaHan: null,
    idFPT: null,
    maHopDong: null,
    soCCCD: null,
    trangThaiGuiDonThuoc: null,
    idNguoiThamDinh: null,
    trangThaiGuiHoSoLC: null,
    vip: {label: 'Tất cả', value: null},
  },
  objectSearchGetListPaginateThuocDefaultNoStatus: {
    page: 0,
    items_per_page: 10,
    soHoSoBaoLanhThuoc: null,
    soHoSoBoiThuong: null,
    maDonHangDoiTac: null,
    hoTen: null,
    thoiDiemNhanHoSo: [],
    findQuaHan: null,
    idFPT: null,
    maHopDong: null,
    soCCCD: null,
    trangThaiGuiDonThuoc: null,
    idNguoiThamDinh: null,
    trangThaiGuiHoSoLC: null,
    vip: {label: 'Tất cả', value: null},
  },
  showPopupCanhBaoDuyetHoSoBLT: false,
  showPopupDuyetHoSoBLT: false,
  showPopupTuChoiHoSoBLT: false,
  dataLoiDuyetHoSoBLT: {},
  loadingBtnPopupDuyetHuyDuyet: false,
  loadingDuyetHoSoBltNhieu: false,
  showPopupCanhBaoVuotHanMucDuyetHoSoBLT: false,
  loadingBtnGuiDonThuoc: false,
  loadingBtnDongBoDonThuoc: false,
  showPopupGuiDonThuocDanhSachHoSoBLT: false,
  tongSoLuongBanGhiDanhSach: null,
  listTDV: [],
  loadingBtnXacNhanDoiSoat: false,
  showPopupDoiSoatHoSoBLT: false,
  loadingBtnInToTrinh: false,
}

export const hoSoBLTSlice = createSlice({
  name: 'hoSoBLT',
  initialState,
  reducers: {
    getListHoSoBLT(state, action) {
      state.loadingListhoSoBLT = true
    },
    getListHoSoBLTSuccess(state, action) {
      state.loadingListhoSoBLT = false
      state.listHoSoBLT = action.payload.results
      state.totalListHoSoBLT = action.payload.total
    },
    getListHoSoBLTFailed(state, action) {
      state.loadingListhoSoBLT = false
      state.listHoSoBLT = []
    },
    setObjectSearchGetListPaginateThuoc(state, action) {
      state.objectSearchGetListPaginateThuoc = {
        ...state.objectSearchGetListPaginateThuoc,
        ...action.payload,
      }
      state.objectSearchGetListPaginateThuocFilter = {
        ...state.objectSearchGetListPaginateThuocFilter,
        ...action.payload,
      }
    },
    setObjectSearchGetListPaginateThuocFilter(state, action) {
      state.objectSearchGetListPaginateThuocFilter = {
        ...state.objectSearchGetListPaginateThuocFilter,
        ...action.payload,
      }
    },
    setObjectSearchGetListPaginateThuocFilterNoApply(state) {
      state.objectSearchGetListPaginateThuocFilter =
        initialState.objectSearchGetListPaginateThuocFilter
    },
    getChiTietHoSoBLT(state, action) {
      state.loadingChiTietHoSoBLT = true
    },
    getChiTietHoSoBLTSuccess(state, action) {
      state.loadingChiTietHoSoBLT = false
      state.chiTietHoSoBLT = action.payload
    },
    getChiTietHoSoBLTFailed(state, action) {
      state.loadingChiTietHoSoBLT = false
      state.chiTietHoSoBLT = null
    },
    setChiTietHoSoBLT(state, action) {
      state.chiTietHoSoBLT = action.payload
    },
    getChiTietHoSoBLTTabThongTinHoSo(state, action) {},
    getChiTietHoSoBLTTabThongTinHoSoSuccess(state, action) {
      state.listChiTietBLTTabThongTinHoSo = action.payload?.result
    },
    getChiTietHoSoBLTTabThongTinHoSoFailed(state, action) {
      state.listChiTietBLTTabThongTinHoSo = []
    },
    getChiTietHoSoBLTTabQuyenLoiBaoHiem(state, action) {
      state.loadingChiTietBLTTabQuyenLoiBaoHiem = true
    },
    getChiTietHoSoBLTTabQuyenLoiBaoHiemSuccess(state, action) {
      function buildTree(data) {
        const tree = []
        const map = {}

        // Tạo map để truy cập nhanh
        data.forEach((item) => {
          map[item.id] = {...item, children: []}
        })

        data.forEach((item) => {
          if (item.idParent && map[item.idParent]) {
            // Nếu có idParent và idParent tồn tại trong map, thêm vào children
            map[item.idParent].children.push(map[item.id])
          } else {
            // Nếu không có idParent hoặc idParent không tồn tại, thêm trực tiếp vào tree
            tree.push(map[item.id])
          }
        })

        return tree
      }

      const groupedData = buildTree(action.payload?.result)
      state.listChiTietBLTTabQuyenLoiBaoHiem = groupedData
      state.listChiTietBLTTabQuyenLoiBaoHiemWithoutGroup = action.payload?.result
      state.loadingChiTietBLTTabQuyenLoiBaoHiem = false
    },
    getChiTietHoSoBLTTabQuyenLoiBaoHiemFailed(state, action) {
      state.listChiTietBLTTabQuyenLoiBaoHiem = []
      state.loadingChiTietBLTTabQuyenLoiBaoHiem = false
    },
    setDataQuyenLoiBaoHiem(state, action) {
      state.listChiTietBLTTabQuyenLoiBaoHiem = action.payload
    },
    getDsThongTinHoaDon(state, action) {
      state.loadingDsThongTinHoaDon = true
    },
    getDsThongTinHoaDonSuccess(state, action) {
      state.dsThongTinHoaDon = action.payload?.result?.map((item, index) => ({
        ...item,
        stt: index + 1,
      }))
      state.loadingDsThongTinHoaDon = false
    },
    getDsThongTinHoaDonFailed(state, action) {
      state.dsThongTinHoaDon = []
      state.loadingDsThongTinHoaDon = false
    },
    capNhatHoSoBLT(state, action) {},
    capNhatHoSoBLTSuccess(state, action) {
      state.dataLoiCanhBaoHoSoBlt = []
    },
    capNhatHoSoBLTFailed(state, action) {
      state.dataLoiCanhBaoHoSoBlt = action.payload
    },
    getListFileDinhKemBLT(state, action) {
      state.loadingDsThuoc = true
    },
    getListFileDinhKemBLTSuccess(state, action) {
      let convertedFiles
      const dsFileDinhKem = action.payload?.result
      if (dsFileDinhKem && Array.isArray(dsFileDinhKem)) {
        dsFileDinhKem.sort((a, b) => {
          const minDateA = Math.min(...a.files?.map((file) => file.createdAt))
          const minDateB = Math.min(...b.files?.map((file) => file.createdAt))
          return minDateA - minDateB
        })
        convertedFiles = dsFileDinhKem.flatMap((fileObject, index) => {
          return fileObject.files.map((file) => {
            // Xử lý ngayNhanBoSung với giá trị đã chắc chắn được tính toán
            return {
              stt: index + 1,
              ten: file.ten,
              url: file.url,
              extension: file.extension,
              idDMLoaiFile: fileObject.id,
              tenDMLoaiFile: fileObject.ten,
              id: file.id,
              rowId: uuid(),
              ghiChu: file.ghiChu,
              createdAt: file.createdAt,
              isEdit: file.benhVien,
              ngayNhanBoSung: file.ngayNhanBoSung,
              ghiChuNDBH: file.ghiChuNDBH,
            }
          })
        })
      }
      state.listFileDinhKemBLT = convertedFiles
    },
    getListFileDinhKemBLTFailed(state, action) {
      state.listFileDinhKemBLT = []
    },
    getDsThongTinThuoc(state, action) {
      state.loadingDsThuoc = true
    },
    getDsThongTinThuocSuccess(state, action) {
      state.dsThongTinThuoc = action.payload?.result
      state.loadingDsThuoc = false
    },
    getDsThongTinThuocFailed(state, action) {
      state.dsThongTinThuoc = []
      state.loadingDsThuoc = false
    },
    postDuyetBlt(state, action) {},
    huyDuyettBlt(state, action) {},
    getChiTietHoSoBLTTabTienTrinhXuLy(state, action) {},
    getChiTietHoSoBLTTabTienTrinhXuLySuccess(state, action) {
      state.listChiTietBLTTabTienTrinhXuLy = action.payload.result
    },
    getChiTietHoSoBLTTabTienTrinhXuLyFailed(state, action) {},
    openPopupCanhBaoHoSoBlt(state) {
      state.isPopupOpenCanhBaoHoSoBlt = true
    },
    // Action đóng popup
    closePopupCanhBaoHoSoBlt(state) {
      state.isPopupOpenCanhBaoHoSoBlt = false
    },
    getTongHanMuc(state, action) {},
    getTongHanMucSuccess(state, action) {
      state.listTongHanMuc = action.payload.result
    },
    getTongHanMucFailed(state, action) {},
    // Popup cảnh báo
    setShowPopupCanhBaoHoSoBLT: (state, action) => {
      state.showPopupCanhBaoDuyetHoSoBLT = action.payload
    },
    setShowPopupCanhBaoVuotHanMucHoSoBLT: (state, action) => {
      state.showPopupCanhBaoVuotHanMucDuyetHoSoBLT = action.payload
    },
    setShowPopupGuiDonThuocDanhSachHoSoBLT: (state, action) => {
      state.showPopupGuiDonThuocDanhSachHoSoBLT = action.payload
    },
    // Popup duyệt hồ sơ blt
    setShowPopupDuyetHoSoBLT: (state, action) => {
      state.showPopupDuyetHoSoBLT = action.payload
    },
    // Popup từ chối hồ sơ blt
    setShowPopupTuChoiHoSoBLT: (state, action) => {
      state.showPopupTuChoiHoSoBLT = action.payload
    },
    // Popup đối soát hồ sơ blt
    setShowPopupXacNhanDoiSoatHoSoBLT: (state, action) => {
      state.showPopupDoiSoatHoSoBLT = action.payload
    },
    // Data lỗi api trả ra khi duyệt
    setDataLoiDuyetHoSoBLT: (state, action) => {
      state.dataLoiDuyetHoSoBLT = action.payload
    },
    // Loading btn trong popup duyệt
    setLoadingBtnPopupDuyetHuyDuyet: (state, action) => {
      state.loadingBtnPopupDuyetHuyDuyet = action.payload
    },
    // setListHoaDonBLT
    setListHoaDonBLT: (state, action) => {
      state.dsThongTinHoaDonTemp = action.payload // Tạo bản sao từng object
    },
    // duyệt hồ sơ blt
    duyetHoSoBLT(state, action) {
      state.loadingDuyetHoSoBltNhieu = true
    },
    duyetHoSoBLTSuccess(state, action) {
      state.loadingDuyetHoSoBltNhieu = false
    },
    duyetHoSoBLTFailed(state, action) {
      state.loadingDuyetHoSoBltNhieu = false
    },
    // gửi đơn thuốc tab tiếp nhận yêu cầu
    guiDonThuoc(state, action) {
      state.loadingBtnGuiDonThuoc = true
    },
    guiDonThuocSuccess(state, action) {
      state.loadingBtnGuiDonThuoc = false
    },
    guiDonThuocFailed(state, action) {
      state.loadingBtnGuiDonThuoc = false
    },
    // đồng bộ đơn thuốc khi duyệt hoặc hủy duyệt hồ sơ blt
    dongBoDonThuoc(state, action) {
      state.loadingBtnDongBoDonThuoc = true
    },
    dongBoDonThuocSuccess(state, action) {
      state.loadingBtnDongBoDonThuoc = false
    },
    dongBoDonThuocFailed(state, action) {
      state.loadingBtnDongBoDonThuoc = false
    },
    // số lượng ở mọi tab
    getSoLuongDanhSach(state, action) {
      state.tongSoLuongBanGhiDanhSach = null
    },
    getSoLuongDanhSachSuccess(state, action) {
      state.tongSoLuongBanGhiDanhSach = action.payload.data
    },
    getSoLuongDanhSachFaield(state, action) {
      state.tongSoLuongBanGhiDanhSach = null
    },
    // display tên thẩm định viên
    getDisplayTenThamDinhVien(state, action) {
      state.listTDV = []
    },
    getDisplayTenThamDinhVienSuccess(state, action) {
      console.log(action, 'accctrionns')
      const dataMapping = action.payload.data.result?.map((item) => ({
        ...item,
        label: item?.username + '-' + item?.hoTen,
        value: item?.id,
      }))
      state.listTDV = dataMapping
    },
    getDisplayTenThamDinhVienFailed(state, action) {
      state.listTDV = []
    },
    // xác nhận đối soát
    xacNhanDoiSoat(state, action) {
      state.loadingBtnXacNhanDoiSoat = true
    },
    xacNhanDoiSoatSuccess(state, action) {
      state.loadingBtnXacNhanDoiSoat = false
    },
    xacNhanDoiSoatFailed(state, action) {
      state.loadingBtnXacNhanDoiSoat = false
    },
    // in giấy tờ trình
    printFilePdfToTrinh(state, action) {
      state.loadingBtnInToTrinh = true
    },
    printFilePdfToTrinhSuccess(state, action) {
      state.loadingBtnInToTrinh = false
    },
    printFilePdfToTrinhFailed(state, action) {
      state.loadingBtnInToTrinh = false
    },
  },
})
export const hoSoBLTActions = hoSoBLTSlice.actions
const hoSoBLTReducer = hoSoBLTSlice.reducer

export default hoSoBLTReducer

export const selectListChiTietBLTTabThongTinHoSo = (state) =>
  state.hoSoBLT.listChiTietBLTTabThongTinHoSo
export const selectListChiTietBLTTabQuyenLoiBaoHiem = (state) =>
  state.hoSoBLT.listChiTietBLTTabQuyenLoiBaoHiem
export const selectLoadingChiTietBLTTabQuyenLoiBaoHiem = (state) =>
  state.hoSoBLT.loadingChiTietBLTTabQuyenLoiBaoHiem
export const selectListChiTietBLTTabQuyenLoiBaoHiemWithoutGroup = (state) =>
  state.hoSoBLT.listChiTietBLTTabQuyenLoiBaoHiemWithoutGroup
export const selectDsThongTinThuoc = (state) => state.hoSoBLT.dsThongTinThuoc
export const selectLoadingDsThuoc = (state) => state.hoSoBLT.loadingDsThuoc
export const selectListFileDinhKemBLT = (state) => state.hoSoBLT.listFileDinhKemBLT
export const selectDsThongTinHoaDon = (state) => state.hoSoBLT.dsThongTinHoaDon
export const selectLoadingDsThongTinHoaDon = (state) => state.hoSoBLT.loadingDsThongTinHoaDon
export const selectListChiTietBLTTabTienTrinhXuLy = (state) =>
  state.hoSoBLT.listChiTietBLTTabTienTrinhXuLy
