import {call, put, takeEvery} from 'redux-saga/effects'
import {lichSuKcbActions} from './lichSuKcbSlice'
import {handleError} from '_metronic/helpers'
import {getHopDongByNdbhService, getListCskcbService, getListLoaiKcbService, getPaginateService, getThongTinCaNhanTheoHoSoService} from './service'

function* getListLoaiKcbSaga({payload}) {
  try {
    const result = yield call(getListLoaiKcbService, payload)

    if (result) {
      yield put({
        type: lichSuKcbActions.getListLoaiKcbSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: lichSuKcbActions.getListLoaiKcbFailed.type,
    })
    handleError(e)
  }
}

function* getListCskcbSaga({payload}) {
  try {
    const result = yield call(getListCskcbService, payload)

    if (result) {
      yield put({
        type: lichSuKcbActions.getListCskcbSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: lichSuKcbActions.getListCskcbFailed.type,
    })
    handleError(e)
  }
}

function* getPaginateSaga({payload}) {
  try {
    const result = yield call(getPaginateService, payload)

    if (result) {
      yield put({
        type: lichSuKcbActions.getPaginateSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: lichSuKcbActions.getPaginateFailed.type,
    })
    console.log(e , 'e');
    handleError(e)
  }
}

function* getListSoHDByNdbhSaga({payload}) {
  try {
    const result = yield call(getHopDongByNdbhService, payload)

    if (result) {
      yield put({
        type: lichSuKcbActions.getHopDongByNdbhSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: lichSuKcbActions.getHopDongByNdbhFailed.type,
    })
    handleError(e)
  }
}

function* getListThongTinCaNhanTheoHoSoSaga({payload}) {
  try {
    const result = yield call(getThongTinCaNhanTheoHoSoService, payload)

    if (result) {
      yield put({
        type: lichSuKcbActions.getThongTinCaNhanTheoHoSoSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: lichSuKcbActions.getThongTinCaNhanTheoHoSoFailed.type,
    })
    handleError(e)
  }
}
export default function* lichSuKcbSaga() {
  yield takeEvery(lichSuKcbActions.getListLoaiKcb.type, getListLoaiKcbSaga)
  yield takeEvery(lichSuKcbActions.getListCskcb.type, getListCskcbSaga)
  yield takeEvery(lichSuKcbActions.getPaginate.type, getPaginateSaga)
  yield takeEvery(lichSuKcbActions.getHopDongByNdbh.type, getListSoHDByNdbhSaga)
  yield takeEvery(lichSuKcbActions.getThongTinCaNhanTheoHoSo.type, getListThongTinCaNhanTheoHoSoSaga)
}
