import {toast} from 'react-toastify'

export default function ToastMessage(
  type: String,
  message: any,
  delay: number = 0,
  duration: number = 3000
) {
  switch (type) {
    case 'error':
      toast.error(message || 'Đã xảy ra lỗi', {delay: delay, autoClose: duration})
      break
    case 'success':
      toast.success(message, {delay: delay, autoClose: duration})
      break
    case 'warning':
      toast.warning(message || 'Đã xảy ra lỗi', {delay: delay, autoClose: duration})
      break
  }
}
