import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  listCoSoKCBHopDong: [],
  objectSearchDanhSachCoSo: {
    page: 0,
    items_per_page: 10,
    sortField: null,
    sortOrder: null,
    ma: null,
    ten: null,
    blLoaiTruString: null,
    phanLoaiCoSo: null,
  },
  objectSearchDanhSachCoSoDefault: {
    page: 0,
    items_per_page: 10,
    sortField: null,
    sortOrder: null,
    ma: null,
    ten: null,
    blLoaiTruString: null,
    phanLoaiCoSo: null,
  },
  totalDanhSachCoSo: null,
  loadingPaginate: false,
}

export const contractStepSevenSlice = createSlice({
  name: 'contractStepSeven',
  initialState,
  reducers: {
    // hợp đồng
    getListDanhSachCoSo(state, action) {
      state.loadingPaginate = true
    },
    getListDanhSachCoSoSuccess(state, action) {
      state.listCoSoKCBHopDong = action.payload.results
      state.totalDanhSachCoSo = action.payload.total
      state.loadingPaginate = false
    },
    getListDanhSachCoSoFailed(state) {
      state.danhSachNguoiDuocBaoHiem = []
      state.loadingPaginate = false
    },
    setObjectSearchDanhSachCoSo(state, action) {
      state.objectSearchDanhSachCoSo = {...state.objectSearchDanhSachCoSo, ...action.payload}
    },
    setLoadingPage(state, action) {
      state.loadingPage = action.payload
    },
  },
})
export const contractStepSevenActions = contractStepSevenSlice.actions
const contractStepSevenReducer = contractStepSevenSlice.reducer

export default contractStepSevenReducer
