import {createSlice} from '@reduxjs/toolkit'

const initialState = {
  loadingDanhSachNguoiDuocBaoHiem: false,
  danhSachNguoiDuocBaoHiem: [],
  danhSachLoaiKhachHang: [],
  loadingDanhSachLoaiKhachHang: false,
  totalPageDanhSachNguoiDuocBaoHiem: null,
  objectSearchContractStepThird: {
    page: 0,
    items_per_page: 10,
    searchHanMucConLai: [0, 0],
    thoiHanTuNgay: [0, 0],
    thoiHanDenNgay: [0, 0],
    searchHoTen: null,
    searchGioiTinh: null,
    searchNgaySinh: null,
    searchSoCCCD: null,
    searchSoDienThoai: null,
    searchCapDo: null,
    idLoaiNguoiDuocBaoHiem: null,
    soTheBaoHiem: null,
    mucLuong: null,
    soTaiKhoan: null,
    nganHangId: null,
    vip: null,
    trangThaiNguoiDuocBaoHiem: null,
  },
  objectSearchContractStepThirdDefault: {
    page: 0,
    items_per_page: 10,
    searchHanMucConLai: [0, 0],
    thoiHanTuNgay: [0, 0],
    thoiHanDenNgay: [0, 0],
    searchHoTen: null,
    searchGioiTinh: null,
    searchNgaySinh: null,
    searchSoCCCD: null,
    searchSoDienThoai: null,
    searchCapDo: null,
    idLoaiNguoiDuocBaoHiem: null,
    soTheBaoHiem: null,
    mucLuong: null,
    soTaiKhoan: null,
    nganHangId: null,
    vip: null,
    trangThaiNguoiDuocBaoHiem: null,
  },
  idHopDong: '',
  listNganHang: [],
  listLoaiNguoiDuocBaoHiem: [],
  listCauHinhDongChiTra: [],
  listTenCongTyTapDoan: [],
  listTenCongTyThanhVien: [],
}

export const contractStepThirdSlice = createSlice({
  name: 'contractStepThird',
  initialState,
  reducers: {
    // hợp đồng
    getListContractStepThird(state, action) {
      state.loadingDanhSachNguoiDuocBaoHiem = true
    },
    getListContractStepThirdSuccess(state, action) {
      // console.log('actionstepthird', action)
      state.danhSachNguoiDuocBaoHiem = action.payload.results
      state.loadingDanhSachNguoiDuocBaoHiem = false
      state.totalPageDanhSachNguoiDuocBaoHiem = action.payload.total
    },
    getListContractStepThirdFailed(state) {
      state.loadingDanhSachNguoiDuocBaoHiem = false
      state.danhSachNguoiDuocBaoHiem = []
    },
    // object search hợp đồng
    setObjectSearchContractStepThird(state, action) {
      state.objectSearchContractStepThird = {
        ...state.objectSearchContractStepThird,
        ...action.payload,
      }
    },
    setObjectSearchNoMergeContractStepThird(state, action) {
      // console.log(action, state)
      state.objectSearchContractStepThird = {...action.payload}
    },
    setIdHopDong(state, action) {
      state.idHopDong = action.payload
    },
    getDanhSachLoaiKhachHang(state, action) {
      state.loadingDanhSachLoaiKhachHang = true
    },
    getDanhSachLoaiKhachHangSuccess(state, action) {
      // console.log('actionstepthird32', action)
      state.danhSachLoaiKhachHang = action.payload
      state.loadingDanhSachLoaiKhachHang = false
    },
    getDanhSachLoaiKhachHangFailed(state) {
      state.loadingDanhSachLoaiKhachHang = false
      state.danhSachLoaiKhachHang = []
    },
    getDanhSachNganHang(state, action) {
      state.listNganHang = []
    },
    getDanhSachNganHangSuccess(state, action) {
      // console.log('actionstepthird32', action)
      state.listNganHang = action.payload
    },
    getDanhSachNganHangFailed(state) {
      state.listNganHang = []
    },
    getDanhSachLoaiNguoiDuocBH(state, action) {
      state.listLoaiNguoiDuocBaoHiem = []
    },
    getDanhSachLoaiNguoiDuocBHSuccess(state, action) {
      // console.log('actionstepthird32', action)
      state.listLoaiNguoiDuocBaoHiem = action.payload
    },
    getDanhSachLoaiNguoiDuocBHFailed(state) {
      state.listLoaiNguoiDuocBaoHiem = []
    },
    unmountStepThird(state) {
      state.danhSachNguoiDuocBaoHiem = []
      state.loadingDanhSachNguoiDuocBaoHiem = true
    },
    getDanhSachCauHinhDongChiTra(state, action) {
      state.listCauHinhDongChiTra = []
    },
    getDanhSachCauHinhDongChiTraSuccess(state, action) {
      const data = action.payload.data.result?.map((item) => ({
        label: item.tyLe,
        value: item.id,
        loai: item.ten,
      }))
      state.listCauHinhDongChiTra = data
    },
    getDanhSachCauHinhDongChiTraFailed(state) {
      state.listCauHinhDongChiTra = []
    },
    getDanhSachTenCongTyTapDoan(state, action) {
      state.listTenCongTyTapDoan = []
    },
    getDanhSachTenCongTyTapDoanSuccess(state, action) {
      const data = action.payload?.map((e) => ({
        value: e?.id,
        label: e?.ten,
      }))
      state.listTenCongTyTapDoan = data
    },
    getDanhSachTenCongTyTapDoanFailed(state) {
      state.listTenCongTyTapDoan = []
    },
    getDanhSachTenCongTyThanhVien(state, action) {
      state.listTenCongTyThanhVien = []
    },
    getDanhSachTenCongTyThanhVienSuccess(state, action) {
      const data = action.payload?.map((e) => ({
        value: e?.id,
        label: e?.ten,
      }))
      state.listTenCongTyThanhVien = data
    },
    getDanhSachTenCongTyThanhVienFailed(state, action) {
      state.listTenCongTyThanhVien = []
    },
  },
})
export const contractStepThirdActions = contractStepThirdSlice.actions
const contractStepThirdReducer = contractStepThirdSlice.reducer

export default contractStepThirdReducer

export const selectObjectSearchContractStepThird = (state) =>
  state.contractStepThird.objectSearchContractStepThird
export const selectTotalPageDanhSachNguoiDuocBaoHiem = (state) =>
  state.contractStepThird.totalPageDanhSachNguoiDuocBaoHiem
export const selectLoadingDanhSachNguoiDuocBaoHiem = (state) =>
  state.contractStepThird.loadingDanhSachNguoiDuocBaoHiem
export const selectDanhSachNguoiDuocBaoHiem = (state) =>
  state.contractStepThird.danhSachNguoiDuocBaoHiem
export const selectIdHopDong = (state) => state.contractStepThird.idHopDong
export const selectDanhSachLoaiKhachHang = (state) => state.contractStepThird.danhSachLoaiKhachHang
export const selectListNganHang = (state) => state.contractStepThird.listNganHang
export const selectListLoaiNguoiDuocBH = (state) => state.contractStepThird.listLoaiNguoiDuocBaoHiem
export const selectObjectSearchContractStepThirdDefault = (state) =>
  state.contractStepThird.objectSearchContractStepThirdDefault
export const selectListCauHinhDongChiTra = (state) => state.contractStepThird.listCauHinhDongChiTra
export const selectListTenCongTyTapDoan = (state) => state.contractStepThird.listTenCongTyTapDoan
export const selectLlistTenCongTyThanhVien = (state) =>
  state.contractStepThird.listTenCongTyThanhVien
