import {removeNullFields} from '_metronic/helpers'
import {api} from 'app/config/api'
import axios from 'axios'

export const getListLoaiKcbService = (dataPayload) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CONTRACT}/loai-co-so/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListCskcbService = (dataPayload) => {
  console.log(dataPayload, 'data')
  const data = {
    ...dataPayload,
    tenLoaiKCB:
      dataPayload?.tenLoaiKCB[0] === null ? dataPayload?.tenLoaiKCB[0] : dataPayload?.tenLoaiKCB,
    fetchChild: false,
  }
  const filteredObject = removeNullFields(data)
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CONTRACT}/co-so-kcb/display` + objString)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getPaginateService = (dataPayload) => {
  console.log('data paginte', dataPayload)
  const {id, data, page, items_per_page} = dataPayload
  const objectPaginate = {
    ho_ten: data?.hoTen,
    ngay_sinh: data?.ngaySinh,
    so_cccd: data?.soCCCDCMT ?? data?.soCCCD,
    gioi_tinh: data?.gioiTinh,
    page: page / items_per_page,
    items_per_page: items_per_page,
  }
  const filteredObject = removeNullFields(objectPaginate)
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  const payload = Object.entries({
    tenCskcb: dataPayload?.tenCskcb?.map((item) => item.label.split('-').pop().trim()) ?? null,
    tenLoaiKCB:
      Array.isArray(dataPayload?.tenLoaiKCB) &&
      dataPayload?.tenLoaiKCB?.map((item) => item)[0] === null
        ? null
        : dataPayload?.tenLoaiKCB,
    soHoSoBoiThuong: dataPayload?.soHoSoBoiThuong ?? null,
    chanDoanVien: dataPayload?.chuanDoanVien ?? null,
    tenNhomDieuKien: dataPayload?.tenNhomDieuKien ?? null,
    tenQuyenLoiBaoHiem: dataPayload?.tenQuyenLoiBaoHiem ?? null,
    ghiChu: dataPayload?.ghiChu ?? null,
    ngayVaoVien: dataPayload?.ngayVaoVien ?? null,
    ngayRaVien: dataPayload?.ngayRaVien ?? null,
    lyDoTuChoiString: dataPayload?.lyDoTuChoiString ?? null,
    trangThaiHoSoString: dataPayload?.trangThaiHoSoString?.value ?? null,
    tienLanhDaoDuyet: dataPayload?.tienLanhDaoDuyet ?? null,
    tienVienPhi: dataPayload?.tienVienPhi ?? null,
    maHopDong:
      dataPayload?.maHopDong && Array.isArray(dataPayload?.maHopDong)
        ? dataPayload?.maHopDong?.[0] === null
          ? dataPayload?.maHopDong?.[0]
          : dataPayload?.maHopDong?.map((item) => item.label)
        : !dataPayload?.maHopDong === null
        ? [dataPayload?.maHopDong]
        : null,
  }).reduce((acc, [key, value]) => {
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      !(Array.isArray(value) && value.length === 0)
    ) {
      acc[key] = value
    }
    return acc
  }, {})
  console.log(payload, 'payload')
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.API_HOSO_TTTT}/lich-su-ho-so` + objString, payload)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getHopDongByNdbhService = (dataPayload) => {
  console.log('data paginte', dataPayload)
  const objectPaginate = {
    identityNumber: dataPayload?.soCCCDCMT ?? dataPayload?.soCCCD,
    // name: dataPayload?.hoTen,
    // gender: dataPayload?.gioiTinh,
    // birthDay: dataPayload?.ngaySinh,
  }
  const filteredObject = removeNullFields(objectPaginate)
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CONTRACT}/ma-hop-dong/search` + objString)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getThongTinCaNhanTheoHoSoService = (dataPayload) => {
  console.log('data paginte', dataPayload)
  const objectPaginate = {
    soCCCD: dataPayload?.soCCCDCMT ?? dataPayload?.soCCCD,
    // hoTen: dataPayload?.hoTen,
    // gioiTinh: dataPayload?.gioiTinh,
    // ngaySinh: dataPayload?.ngaySinh,
  }
  const filteredObject = removeNullFields(objectPaginate)
  const objString = '?' + new URLSearchParams(filteredObject).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_HOSO_TTTT}/get-filter-lich-su` + objString)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}