import {api} from 'app/config/api'
import axios from 'axios'

// const getDanhSachPhieuThanhToan = async () => {
//   const objectSearchGetListNew = {
//     ...objectSearchGetList,
//     donViId: objectSearchGetList?.donViId?.value,
//     loaiHoSo: objectSearchGetList?.loaiHoSo,
//     maCskcb: objectSearchGetList?.maCskcb?.value,
//     trangThaiDeNghi: objectSearchGetList?.trangThaiDeNghi?.value,
//     trangThaiThanhToan: objectSearchGetList?.trangThaiThanhToan?.value,
//     trangThaiPhieu: objectSearchGetList?.trangThaiPhieu?.value
//       ? objectSearchGetList?.trangThaiPhieu?.value
//       : objectSearchGetList?.trangThaiPhieu,
//   }
//   let params: any = {}
//   if (objectSearchGetListNew) {
//     params = objectSearchGetListNew
//   } else params = ''
//   const objectSearchAll = {
//     ...objectSearchGetListNew,
//     page: first / rows,
//     item_per_page: rows,
//     trangThaiPhieu: objectSearchGetListNew?.trangThaiPhieu,
//     sort: objectOrder?.sortField || 'ngayDeNghi',
//     order: objectOrder?.sortOrder ? (objectOrder?.sortOrder === -1 ? 'DESC' : 'ASC') : 'DESC',
//   }
//   setLoading(true)
//   removeNullFieldsMedicalRecord(objectSearchAll)
//   const objString = '?' + new URLSearchParams(objectSearchAll).toString()
//   axios
//     .get(api.API_DANHSACH_PHIEU_THANHTOAN + objString)
//     // .get('http://localhost:9020/phieu-thanh-toan/paginate' + objString)
//     .then(({data}) => {
//       if (data) {
//         setDataTable(data?.results)
//         setTotalRecords(data?.total)
//       }
//     })
//     .catch((error) => {
//       handleError(error)
//     })
//     .finally(() => {
//       setLoading(false)
//     })
// }
export const getListPhieu = (data) => {
  console.log(data, 'data')
  const {...otherData} = data
  let valueLoaiHS
  if (otherData.loaiHoSo) {
    valueLoaiHS = otherData.loaiHoSo.value
    if (
      typeof valueLoaiHS === 'string' &&
      valueLoaiHS.startsWith('[') &&
      valueLoaiHS.endsWith(']')
    ) {
      valueLoaiHS = valueLoaiHS.substring(1, valueLoaiHS.length - 1)
    }
  }
  const objectTemp = {
    ...otherData,
    page: otherData?.page / otherData?.items_per_page,
    donViId: otherData?.donViId?.value,
    maCskcb: otherData?.maCskcb ? otherData?.maCskcb.value : null,
    trangThaiPhieu:
      Array.isArray(otherData?.trangThaiPhieu) && typeof otherData?.trangThaiPhieu[0] !== 'number'
        ? otherData?.trangThaiPhieu.some((val) => val?.value === null)
          ? null
          : otherData?.trangThaiPhieu?.map((val) => val?.value)
        : otherData?.trangThaiPhieu,
    trangThaiThanhToan:
      Array.isArray(otherData?.trangThaiThanhToan) && typeof otherData?.trangThaiThanhToan[0] !== 'number'
        ? otherData?.trangThaiThanhToan.some((val) => val?.value === null)
          ? null
          : otherData?.trangThaiThanhToan?.map((val) => val?.value)
        : otherData?.trangThaiThanhToan,
    sort: otherData?.sortField || 'ngayDeNghi',
    order: otherData?.sortOrder ? (otherData?.sortOrder === -1 ? 'DESC' : 'ASC') : 'DESC',
    loaiHoSo: otherData.loaiHoSo
      ? typeof valueLoaiHS === 'string' && valueLoaiHS.split('_').length > 1
        ? Number(valueLoaiHS.split('_')[0])
        : valueLoaiHS
      : null,
    maLoaiKcb: otherData.loaiHoSo
      ? otherData.loaiHoSo.dm_ma_loai_kcb
        ? otherData.loaiHoSo.dm_ma_loai_kcb
        : null
      : null,
    vip: otherData.vip !== null ? otherData.vip.value !== undefined ? otherData.vip.value : otherData.vip : null,
  }
  removeNullFieldsMedicalRecord(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_DANHSACH_PHIEU_THANHTOAN + objString)
      .then((results) => {
        resolve(results?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
function removeNullFieldsMedicalRecord(obj) {
  for (const key in obj) {
    if (
      obj[key] === null ||
      obj[key] === 'null' ||
      obj[key] === '' ||
      obj[key]?.length === 0 ||
      obj[key] === 'undefined' ||
      obj[key] === undefined
    ) {
      delete obj[key]
    }
  }
}

export const getStatisticsSoLuongPhieuThanhToanService = (data) => {
  console.log(data, 'data232323')
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_TAO_PHIEU_THANHTOAN +'/statistic' )
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}