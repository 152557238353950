import {call, put, takeEvery} from 'redux-saga/effects'
import {feedbackHistoryAction} from './feedbackSlice'
import {fetchChangeEmailLabel, fetchOcrHistory, fetchOcrStatus} from './service'
import {handleError} from '_metronic/helpers'

function* handleFetchOcrStatus({payload}) {
  try {
    yield put(feedbackHistoryAction.getOcrStatusRequest())
    const response = yield call(
      fetchOcrStatus,
      payload.message_id_hash,
      payload.page,
      payload.limit
    )
    yield put(feedbackHistoryAction.getOcrStatusSuccess(response))
  } catch (error) {
    console.error('Error while fetching OCR status:', error)
    yield put(feedbackHistoryAction.getOcrStatusError(error.message))
    handleError(error)
  }
}

function* handleFetchChangeEmailLabel({payload}) {
  try {
    yield put(feedbackHistoryAction.getChangeEmailLabelRequest())
    const response = yield call(
      fetchChangeEmailLabel,
      payload.message_id_hash,
      payload.page,
      payload.limit
    )
    yield put(feedbackHistoryAction.getChangeEmailLabelSuccess(response))
  } catch (error) {
    console.error('Error while fetching change email label:', error)
    yield put(feedbackHistoryAction.getChangeEmailLabelError(error.message))
    handleError(error)
  }
}

function* handleFetchOcrChangeHistory({payload}) {
  try {
    yield put(feedbackHistoryAction.getOcrChangeHistoryRequest())
    const response = yield call(
      fetchOcrHistory,
      payload.attachment_id,
    )
    yield put(feedbackHistoryAction.getOcrChangeHistorySuccess(response))
  } catch (error) {
    console.error('Error while fetching OCR change history:', error)
    yield put(feedbackHistoryAction.getOcrChangeHistoryError(error.message))
    handleError(error)
  }
}

export default function* feedbackHistorySaga() {
  yield takeEvery('feedbackHistory/getOcrStatus', handleFetchOcrStatus)
  yield takeEvery('feedbackHistory/getChangeEmailLabel', handleFetchChangeEmailLabel)
  yield takeEvery('feedbackHistory/getOcrChangeHistory', handleFetchOcrChangeHistory)
}
