import {phieuDeNghiActions} from './phieuDeNghiSlice'
import {call, put, takeLatest} from 'redux-saga/effects' // Import debounce from redux-saga
import {handleError} from '_metronic/helpers'
import { getListPhieu, getStatisticsSoLuongPhieuThanhToanService } from './service'

function* debounceSearchListPhieu(action) {
  yield call(getListPhieuSaga, {payload: action.payload})
}

function* getListPhieuSaga({payload}) {
  try {
    const result = yield call(getListPhieu, payload)
    if (result) {
      yield put({
        type: phieuDeNghiActions.getListPhieuSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: phieuDeNghiActions.getListPhieuFailed.type,
    })
    handleError(e)
  }
}

function* getStatisticsSoLuongPhieuThanhToanSaga({payload}) {
  try {
    const result = yield call(getStatisticsSoLuongPhieuThanhToanService, payload)
    if (result) {
      yield put({
        type: phieuDeNghiActions.getStatisticsSoLuongPhieuThanhToanSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: phieuDeNghiActions.getStatisticsSoLuongPhieuThanhToanFailed.type,
    })
    handleError(e)
  }
}

export default function* phieuDeNghiSaga() {
  yield takeLatest(phieuDeNghiActions.getListPhieu.type, debounceSearchListPhieu)
  yield takeLatest(phieuDeNghiActions.getStatisticsSoLuongPhieuThanhToan.type, getStatisticsSoLuongPhieuThanhToanSaga)

}
