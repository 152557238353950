import {createSlice} from '@reduxjs/toolkit'
import {act} from 'react'

const initialState = {
  listLoaiKcb: [],
  loadingListLoaiKcb: false,
  loadingCSKCB: false,
  listCSKCB: [],
  objectSearchSingeCskcb: {
    page: 0,
    items_per_page: 10,
    tenLoaiKCB: [null],
  },
  objectSearchPaginate: {
    page: 0,
    items_per_page: 10,
    maHopDong: null,
  },
  objectSearchPaginateDefault: {
    page: 0,
    items_per_page: 10,
    maHopDong: null,
    tenCskcb: null,
  },
  listPaginate: [],
  totalPaginate: null,
  loadingPaginate: false,
  isFirstRenderListPaginate: true,
  listHopDongByNDBH: [],
  tongTienYCBT: null,
  tongTienLanhDaoDuyet: null,
  listCskcbTheoHoSo: [],
  listPhanLoaiKcbTheoHoSo: [],
  listTinhTrangDuyetTheoHoSo: [],
  loadingThongTinCaNhanTheoHoSo: false,
}

export const lichSuKcbSlice = createSlice({
  name: 'lichSuKcb',
  initialState,
  reducers: {
    // loại kcb
    getListLoaiKcb(state, action) {
      state.loadingListLoaiKcb = true
    },
    getListLoaiKcbSuccess(state, action) {
      state.loadingListLoaiKcb = false
      state.listLoaiKcb = action.payload.result
    },
    getListLoaiKcbFailed(state) {
      state.loadingListLoaiKcb = false
    },
    // cơ sở kcb
    getListCskcb(state, action) {
      state.loadingCSKCB = true
      if (state.objectSearchSingeCskcb.page === 0) {
        state.listCSKCB = []
      }
    },
    getListCskcbSuccess(state, action) {
      if (state.objectSearchSingeCskcb.page === 0) {
        state.listCSKCB = action.payload.result.results
      } else {
        // Thêm dữ liệu mới nếu page > 0
        state.listCSKCB = [...state.listCSKCB, ...action.payload.result.results]
      }
      state.loadingCSKCB = false
    },
    getListCskcbFailed(state, action) {
      state.loadingCSKCB = false
    },
    setObjectSearchSingeCskcb(state, action) {
      state.objectSearchSingeCskcb = {
        ...state.objectSearchSingeCskcb,
        ...action.payload,
      }
    },
    // paginate
    getPaginate(state, action) {
      state.loadingPaginate = true
    },
    getPaginateSuccess(state, action) {
      console.log(action, 'action')
      state.loadingPaginate = false
      state.listPaginate = action.payload.results
      state.totalPaginate = action.payload.total
      state.tongTienYCBT = action.payload.tongTienVienPhi
      state.tongTienLanhDaoDuyet = action.payload.tongTienLanhDaoDuyet
    },
    getPaginateFailed(state, action) {
      state.loadingPaginate = false
    },
    setObjectSearchPaginate(state, action) {
      state.objectSearchPaginate = action.payload
    },
    setIsFirstRenderListPaginate(state, action) {
      state.isFirstRenderListPaginate = action.payload
    },
    getHopDongByNdbh(state, action) {},
    getHopDongByNdbhSuccess(state, action) {
      state.listHopDongByNDBH = action.payload.result
    },
    getHopDongByNdbhFailed(state, action) {},
    resetUserData: (state) => {
      state.objectSearchPaginate = state.objectSearchPaginateDefault // Reset lại dữ liệu
    },
    getThongTinCaNhanTheoHoSo(state, action) {
      state.loadingThongTinCaNhanTheoHoSo = true
    },
    getThongTinCaNhanTheoHoSoSuccess(state, action) {
      state.listCskcbTheoHoSo = action.payload.result.cskcb.map((item) => ({
        label: item,
        value: item,
      }))

      state.listPhanLoaiKcbTheoHoSo = action.payload.result.phanLoaiKCB.map((item) => ({
        label: item.normalize("NFC"),
        value: item.normalize("NFC"),
      }))

      state.listTinhTrangDuyetTheoHoSo = action.payload.result.tinhTrangDuyet.map((item) => ({
        label: item,
        value: item,
      }))
      state.loadingThongTinCaNhanTheoHoSo = false
    },
    getThongTinCaNhanTheoHoSoFailed(state, action) {
      state.loadingThongTinCaNhanTheoHoSo = false
    },
  },
})
export const lichSuKcbActions = lichSuKcbSlice.actions
const lichSuKcbReducer = lichSuKcbSlice.reducer

export default lichSuKcbReducer
