import {configureStore} from '@reduxjs/toolkit'
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist'
import {encryptTransform} from 'redux-persist-transform-encrypt'
import storage from 'redux-persist/lib/storage'
import createSagaMiddleware from 'redux-saga'
import rootReducers from './rootReducer'
import rootSaga from './rootSaga'

const sagaMiddleware = createSagaMiddleware()

const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: ['common', 'searchFilter', 'traCuu'],
    transforms: [
      encryptTransform({
        secretKey: 'abc123',
      }),
    ],
  },
  rootReducers
)

const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }).concat(sagaMiddleware),
})
sagaMiddleware.run(rootSaga)

export const persistor = persistStore(store)

export default store
