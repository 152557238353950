import {createSlice} from '@reduxjs/toolkit'
import { act } from 'react'

const initialState = {
  objectSearchPhieu: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: [{label: 'Chờ duyệt', value: [1, 5]}],
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy : null
  },
  objectSearchPhieuFilter: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: '',
    hoTen: '',
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: null,
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy : null
  },
  objectSearchPhieuFilterTabTatCa: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: '',
    hoTen: '',
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy : null
  },
  objectSearchPhieuDefault: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: null,
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy: true,
  },
  objectSearchPhieuDefaultTabBiTraLai: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: [{label: 'Bị trả lại', value: 3}],
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy: true,
  },
  objectSearchPhieuDefaultTabDaDuyet: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: [{label: 'Đã duyệt', value: 2}],
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy: true,
  },
  objectSearchPhieuDefaultTabDangXuLy: {
    page: 0,
    items_per_page: 10,
    soPhieuThanhToan: null,
    maDoiSoatBenhVien: null,
    soHoSoBoiThuong: null,
    maHdbhnt: null,
    hoTen: null,
    donViId: null,
    maCskcb: null,
    vip: null,
    tongTienPhieu: null,
    ngayDeNghi: null,
    trangThaiPhieu: [{label: 'Chờ duyệt', value: [1, 5]}],
    ngayThanhToan: null,
    trangThaiThanhToan: null,
    maGiaoDich: null,
    loiGiaoDich: null,
    loaiHoSo: null,
    maLoaiKCB: null,
    ngayDuyet: null,
    sortField: null,
    sortOrder: null,
    skipCheckNguoiXuLy: null,
  },
  loadingGetListPhieu: false,
  totalGetListPhieu: null,
  listPhieu: [],
  reload: false,
  statisticsSoLuongPhieuThanhToan: null,
  totalAmount:null,
}

export const phieuDeNghiSlice = createSlice({
  name: 'phieuDeNghi',
  initialState,
  reducers: {
    getListPhieu(state, action) {
      state.loadingGetListPhieu = true
    },
    getListPhieuSuccess(state, action) {
      state.listPhieu = action.payload.results
      state.loadingGetListPhieu = false
      state.totalGetListPhieu = action.payload.total
      state.totalAmount=action.payload.totalAmount
    },
    getListPhieuFailed(state) {
      state.loadingGetListPhieu = false
      state.listPhieu = []
    },
    setObjectPhieu(state, action) {
      state.objectSearchPhieu = {...state.objectSearchPhieu, ...action.payload}
      state.objectSearchPhieuFilter = {...state.objectSearchPhieuFilter, ...action.payload}
      state.objectSearchPhieuFilterTabTatCa = {...state.objectSearchPhieuFilterTabTatCa, ...action.payload}
    },
    setObjectPhieuFilter(state, action) {
      state.objectSearchPhieuFilter = {
        ...state.objectSearchPhieuFilter,
        ...action.payload,
      }
      state.objectSearchPhieuFilterTabTatCa = {...state.objectSearchPhieuFilterTabTatCa, ...action.payload}

    },
    setObjectPhieuDefault(state) {
      state.objectSearchPhieuFilter = initialState.objectSearchPhieuFilter
    },
    setObjectPhieuDefaultTabAll(state) {
      state.objectSearchPhieuFilterTabTatCa = initialState.objectSearchPhieuFilterTabTatCa
    },
    setObjectPhieuNoMerge(state, action) {
      console.log(action.payload)
      state.objectSearchPhieu = {...action.payload}
      // state.objectSearchPhieuFilter = {...action.payload}
    },
    getStatisticsSoLuongPhieuThanhToan(state, action) {
    },
    getStatisticsSoLuongPhieuThanhToanSuccess(state, action) {
      state.statisticsSoLuongPhieuThanhToan = action.payload.result
    },
    getStatisticsSoLuongPhieuThanhToanFailed(state, action) {
    },
  },
})
export const phieuDeNghiActions = phieuDeNghiSlice.actions
const phieuDeNghiReducer = phieuDeNghiSlice.reducer

export default phieuDeNghiReducer
export const selectObjectSearchPhieu = (state) => state.phieuDeNghi.objectSearchPhieu
export const selectObjectSearchPhieuDefault = (state) => state.phieuDeNghi.objectSearchPhieuDefault
export const selectObjectSearchPhieuFilter = (state) => state.phieuDeNghi.objectSearchPhieuFilter
export const selectTotalDanhSachPhieu = (state) => state.phieuDeNghi.totalGetListPhieu
export const selectDanhSachPhieu = (state) => state.phieuDeNghi.listPhieu
export const selectLoadingDanhSachPhieu = (state) => state.phieuDeNghi.loadingGetListPhieu
