import {api} from 'app/config/api'
import axios from 'axios'

export const fetchOcrStatus = (message_id_hash, page, limit) => {
  const payload = {
    message_id_hash,
    feedback_type: 'ocr_failed',
    page,
    limit,
  }

  return new Promise((resolve, reject) => {
    axios
      .post(`${api.API_OCR}/api/v1/email/feedback/get-history`, payload)
      .then((response) => {
        const data = response?.data || []
        resolve(Array.isArray(data) ? data : [])
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchChangeEmailLabel = (message_id_hash, page, limit) => {
  const payload = {
    message_id_hash,
    feedback_type: 'email_label',
    page,
    limit,
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${api.API_OCR}/api/v1/email/feedback/get-history`, payload)
      .then((response) => {
        const data = response?.data || []
        resolve(Array.isArray(data) ? data : [])
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const fetchOcrHistory = (attachment_id) => {
  if (attachment_id) {
    const payload = {
      attachment_id,
      feedback_type: 'ocr_result_full',
    }
    return new Promise((resolve, reject) => {
      axios
        .post(`${api.API_OCR}/api/v1/email/feedback/get-history`, payload)
        .then((response) => {
          const data = response?.data || []
          resolve(Array.isArray(data) ? data : [])
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
