import {all, call, put, takeEvery, delay} from 'redux-saga/effects'
import {handleError} from '_metronic/helpers'
import ToastMessage from 'app/components/ToastMessage'
import {hoSoBLTActions} from './hoSoBLTSlice'
import {
  getDetailBLTTabQuyenLoiBaoHiem,
  getDetailBLTTabThongTinHoSo,
  getListHoSoBLT,
  getDetailBLTThongTinThuoc,
  capNhatHoSoBLT,
  getFileDinhKemHoSoBLT,
  getThongTinHoaDonHoSoBLT,
  postDuyetBlt,
  huyDuyetBlt,
  getThongTinTienTrinhXuLyHoSoBLT,
  getTongHanMuc,
  duyetHoSoBltNhieu,
  guiDonThuocDsHoSoBLT,
  dongBoDonThuocHoSoBLT,
  getSoLuongBanGhiDanhSach,
  getDisplayTenTDV,
  xacNhanDoiSoat,
  printFileToTrinhService,
} from './service'
import {debounce} from 'lodash'

function* debounceSearchGetListHoSoBLT(action) {
  // yield delay(5000);
  yield call(handleGetListHoSoBLT, {payload: action.payload})
}

function* handleGetListHoSoBLT({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getListHoSoBLT, payload)
    if (resultsListHoSoBLT?.results) {
      yield put({
        type: hoSoBLTActions.getListHoSoBLTSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getListHoSoBLTFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListChiTietBLTTabThongTinHoSo({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getDetailBLTTabThongTinHoSo, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSoSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSoFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListChiTietBLTTabQuyenLoiBaoHiem({payload}) {
  console.log(payload, 'payload')
  try {
    const resultsListHoSoBLT = yield call(getDetailBLTTabQuyenLoiBaoHiem, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiemSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiemFailed.type,
    })
    handleError(e)
  }
}
function* handleGetListChiTietBLTTabThongTinThuoc({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getDetailBLTThongTinThuoc, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getDsThongTinThuocSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getDsThongTinThuocFailed.type,
    })
    handleError(e)
  }
}
function* handleCapNhatHoSoBLT({payload}) {
  try {
    const resultsListHoSoBLT = yield call(capNhatHoSoBLT, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.capNhatHoSoBLTSuccess.type,
        payload: resultsListHoSoBLT,
      })
      ToastMessage('success', 'Lưu thành công')
      // Gọi action getChiTietHoSoBLTTabThongTinHoSo với id trực tiếp
      if (payload?.id) {
        yield all([
          put(hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSo(payload?.id)), // Truyền id
          put(hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiem({id: payload?.id})), // Truyền id
          put(hoSoBLTActions.getDsThongTinHoaDon(payload?.id)), // Truyền id
          put(hoSoBLTActions.getListFileDinhKemBLT(payload?.id)), // Truyền id
          put(hoSoBLTActions.getDsThongTinThuoc(payload?.id)), // Truyền id
        ])
      }

      // Gọi action getChiTietHoSoBLTTabQuyenLoiBaoHiem với id trực tiếp
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.capNhatHoSoBLTFailed.type,
      payload: e?.response?.data?.detail,
    })
    if (e?.response?.data?.detail) {
      yield put(hoSoBLTActions.openPopupCanhBaoHoSoBlt())
    }
    handleError(e)
  }
}
function* handleGetListChiTietBLTTabFileDinhKem({payload}) {
  try {
    const resultsListFileDinhKem = yield call(getFileDinhKemHoSoBLT, payload)
    if (resultsListFileDinhKem) {
      yield put({
        type: hoSoBLTActions.getListFileDinhKemBLTSuccess.type,
        payload: resultsListFileDinhKem,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getListFileDinhKemBLTFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDsThongTinHoaDon({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getThongTinHoaDonHoSoBLT, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getDsThongTinHoaDonSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getDsThongTinHoaDonFailed.type,
    })
    handleError(e)
  }
}

function* handlePostDuyetHsBaoLanhThuoc({payload}) {
  yield put(hoSoBLTActions.setLoadingBtnPopupDuyetHuyDuyet(true))

  try {
    // Gọi cập nhật hồ sơ trước
    const capNhatResult = yield call(capNhatHoSoBLT, payload)

    if (capNhatResult) {
      yield put({
        type: hoSoBLTActions.capNhatHoSoBLTSuccess.type,
        payload: capNhatResult,
      })

      // Gọi duyệt hồ sơ sau khi cập nhật thành công
      const resultsListHoSoBLT = yield call(postDuyetBlt, payload)
      if (resultsListHoSoBLT) {
        ToastMessage('success', 'Duyệt thành công')
        if (payload) {
          yield all([
            put(hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSo(payload?.id)),
            put(hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiem({id: payload?.id})),
            put(hoSoBLTActions.getDsThongTinHoaDon(payload?.id)),
            put(hoSoBLTActions.getListFileDinhKemBLT(payload?.id)),
            put(hoSoBLTActions.getDsThongTinThuoc(payload?.id)),
            put(hoSoBLTActions.setShowPopupCanhBaoHoSoBLT(false)),
            put(hoSoBLTActions.setDataLoiDuyetHoSoBLT(null)),
            put(hoSoBLTActions.setShowPopupDuyetHoSoBLT(false)),
            put(hoSoBLTActions.setShowPopupCanhBaoVuotHanMucHoSoBLT(false)),
          ])
        }
      }
    }
  } catch (e) {
    const {detail} = e?.response?.data

    if (detail && e?.response?.data?.errorCode !== 'OVERLIMIT') {
      console.log('cgecjiverkuntu')
      yield all([
        put(hoSoBLTActions.setShowPopupCanhBaoHoSoBLT(true)),
        put(hoSoBLTActions.setDataLoiDuyetHoSoBLT(detail)),
      ])
    }
    if (detail && e?.response?.data?.errorCode === 'OVERLIMIT') {
      yield all([
        put(hoSoBLTActions.setShowPopupCanhBaoVuotHanMucHoSoBLT(true)),
        put(hoSoBLTActions.setDataLoiDuyetHoSoBLT(detail)),
      ])
    } else {
      handleError(e)
    }
  } finally {
    yield put(hoSoBLTActions.setLoadingBtnPopupDuyetHuyDuyet(false))
  }
}

function* handlePostHuyDuyetHsBaoLanhThuoc({payload}) {
  yield put(hoSoBLTActions.setLoadingBtnPopupDuyetHuyDuyet(true))

  try {
    // Gọi cập nhật hồ sơ trước
    const capNhatResult = yield call(capNhatHoSoBLT, payload)

    if (capNhatResult) {
      yield put({
        type: hoSoBLTActions.capNhatHoSoBLTSuccess.type,
        payload: capNhatResult,
      })

      // Gọi hủy duyệt hồ sơ sau khi cập nhật thành công
      const resultsListHoSoBLT = yield call(huyDuyetBlt, payload)
      if (resultsListHoSoBLT) {
        ToastMessage('success', 'Từ chối hồ sơ thành công')
        // Gọi action getChiTietHoSoBLTTabThongTinHoSo với id trực tiếp
        if (payload?.id) {
          yield all([
            put(hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSo(payload?.id)), // Truyền id
            put(hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiem({id: payload?.id})), // Truyền id
            put(hoSoBLTActions.getDsThongTinHoaDon(payload?.id)), // Truyền id
            put(hoSoBLTActions.getListFileDinhKemBLT(payload?.id)), // Truyền id
            put(hoSoBLTActions.getDsThongTinThuoc(payload?.id)), // Truyền id
            put(hoSoBLTActions.setShowPopupTuChoiHoSoBLT(false)),
          ])
        }
      }
    }
  } catch (e) {
    const {detail} = e?.response?.data

    if (detail && e?.response?.data?.errorCode !== 'OVERLIMIT') {
      console.log('cgecjiverkuntu')
      yield all([
        put(hoSoBLTActions.setShowPopupCanhBaoHoSoBLT(true)),
        put(hoSoBLTActions.setDataLoiDuyetHoSoBLT(detail)),
      ])
    }
    if (detail && e?.response?.data?.errorCode === 'OVERLIMIT') {
      yield all([
        put(hoSoBLTActions.setShowPopupCanhBaoVuotHanMucHoSoBLT(true)),
        put(hoSoBLTActions.setDataLoiDuyetHoSoBLT(detail)),
      ])
    } else {
      handleError(e)
    }
  } finally {
    yield put(hoSoBLTActions.setLoadingBtnPopupDuyetHuyDuyet(false))
  }
}

function* handleGetDsTienTrinhXuLy({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getThongTinTienTrinhXuLyHoSoBLT, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getChiTietHoSoBLTTabTienTrinhXuLySuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getChiTietHoSoBLTTabTienTrinhXuLyFailed.type,
    })
    handleError(e)
  }
}

function* handleGetDsTongHanMuc({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getTongHanMuc, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getTongHanMucSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getTongHanMucFailed.type,
    })
    handleError(e)
  }
}

function* handleDuyetNhieuHoSoBLT({payload}) {
  const {objectSearch, selectedItems} = payload
  try {
    const resultsListHoSoBLT = yield call(duyetHoSoBltNhieu, selectedItems)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.duyetHoSoBLTSuccess.type,
        payload: resultsListHoSoBLT,
      })
      yield all([
        put(hoSoBLTActions.getListHoSoBLT(objectSearch)), // Truyền id
      ])
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.duyetHoSoBLTFailed.type,
    })
    ToastMessage('error', e?.response?.data?.message)
  }
}
// gửi đơn thuốc danh sách
function* handleGuiDonThuocDanhSachHoSoBLT({payload}) {
  const {objectSearch, selectedItems} = payload
  try {
    const resultsListHoSoBLT = yield call(guiDonThuocDsHoSoBLT, selectedItems)
    if (resultsListHoSoBLT) {
      ToastMessage('success', 'Gửi lại đơn thuốc thành công')
      yield put({
        type: hoSoBLTActions.guiDonThuocSuccess.type,
        payload: resultsListHoSoBLT,
      })
      yield all([
        put(hoSoBLTActions.getListHoSoBLT(objectSearch)), // Truyền id
        put(hoSoBLTActions.setShowPopupGuiDonThuocDanhSachHoSoBLT(false)),
      ])
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.guiDonThuocFailed.type,
    })
    ToastMessage('error', e?.response?.data?.message)
  }
}
// đồng bộ đơn thuốc
function* handleDongBoDonThuocHoSoBLT({payload}) {
  try {
    const resultsListHoSoBLT = yield call(dongBoDonThuocHoSoBLT, payload)
    if (resultsListHoSoBLT) {
      ToastMessage('success', 'Đồng bộ đơn thuốc thành công')
      yield put({
        type: hoSoBLTActions.dongBoDonThuocSuccess.type,
        payload: resultsListHoSoBLT,
      })
      if (payload?.id) {
        yield all([
          put(hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSo(payload?.id)), // Truyền id
          put(hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiem({id: payload?.id})), // Truyền id
          put(hoSoBLTActions.getDsThongTinHoaDon(payload?.id)), // Truyền id
          put(hoSoBLTActions.getListFileDinhKemBLT(payload?.id)), // Truyền id
          put(hoSoBLTActions.getDsThongTinThuoc(payload?.id)), // Truyền id
        ])
      }
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.dongBoDonThuocFailed.type,
    })
    ToastMessage('error', e?.response?.data?.message)
  }
}
// số lượng bản ghi ngoài màn hình ds
function* handleGetSoLuongBanGhi({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getSoLuongBanGhiDanhSach, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getSoLuongDanhSachSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getSoLuongDanhSachFaield.type,
    })
    handleError(e)
  }
}
// display thẩm định viên
function* handleDisplayThamDinhVien({payload}) {
  try {
    const resultsListHoSoBLT = yield call(getDisplayTenTDV, payload)
    if (resultsListHoSoBLT) {
      yield put({
        type: hoSoBLTActions.getDisplayTenThamDinhVienSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.getDisplayTenThamDinhVienFailed.type,
    })
    handleError(e)
  }
}
// xác nhận đối soát
function* handleXacNhanDoiSoat({payload}) {
  try {
    const resultsListHoSoBLT = yield call(xacNhanDoiSoat, payload)
    if (resultsListHoSoBLT) {
      ToastMessage('success', 'Xác nhận đối soát thành công')
      yield put({
        type: hoSoBLTActions.xacNhanDoiSoatSuccess.type,
        payload: resultsListHoSoBLT,
      })
      yield all([
        put(hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSo(payload)), // Truyền id
        put(hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiem({id: payload})), // Truyền id
        put(hoSoBLTActions.getDsThongTinHoaDon(payload)), // Truyền id
        put(hoSoBLTActions.getListFileDinhKemBLT(payload)), // Truyền id
        put(hoSoBLTActions.getDsThongTinThuoc(payload)), // Truyền id
        put(hoSoBLTActions.setShowPopupXacNhanDoiSoatHoSoBLT(false)),
      ])
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.xacNhanDoiSoatFailed.type,
    })
    ToastMessage('error', e?.response?.data?.message)
  }
}
// in giấy tờ trình
function* handlePrintFileToTrinh({payload}) {
  try {
    const resultsListHoSoBLT = yield call(printFileToTrinhService, payload)
    if (resultsListHoSoBLT) {
      console.log(resultsListHoSoBLT, 'dffđf')
      const blob = new Blob([resultsListHoSoBLT?.data], {
        type: 'application/pdf', // Set the type to 'application/pdf' for PDF
      })
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')

      ToastMessage('success', 'In tờ trình bồi thường thành công')
      yield put({
        type: hoSoBLTActions.printFilePdfToTrinhSuccess.type,
        payload: resultsListHoSoBLT,
      })
    }
  } catch (e) {
    yield put({
      type: hoSoBLTActions.printFilePdfToTrinhFailed.type,
    })
    handleError(e)
  }
}

export default function* hoSoBLTSaga() {
  yield takeEvery(hoSoBLTActions.getListHoSoBLT.type, debounceSearchGetListHoSoBLT)
  yield takeEvery(
    hoSoBLTActions.getChiTietHoSoBLTTabThongTinHoSo.type,
    handleGetListChiTietBLTTabThongTinHoSo
  )
  yield takeEvery(
    hoSoBLTActions.getChiTietHoSoBLTTabQuyenLoiBaoHiem.type,
    handleGetListChiTietBLTTabQuyenLoiBaoHiem
  )
  yield takeEvery(hoSoBLTActions.getDsThongTinThuoc.type, handleGetListChiTietBLTTabThongTinThuoc)
  yield takeEvery(hoSoBLTActions.capNhatHoSoBLT.type, handleCapNhatHoSoBLT)
  yield takeEvery(hoSoBLTActions.getListFileDinhKemBLT.type, handleGetListChiTietBLTTabFileDinhKem)
  yield takeEvery(hoSoBLTActions.getDsThongTinHoaDon.type, handleGetDsThongTinHoaDon)
  yield takeEvery(hoSoBLTActions.postDuyetBlt.type, handlePostDuyetHsBaoLanhThuoc)
  yield takeEvery(hoSoBLTActions.huyDuyettBlt.type, handlePostHuyDuyetHsBaoLanhThuoc)
  yield takeEvery(hoSoBLTActions.getChiTietHoSoBLTTabTienTrinhXuLy.type, handleGetDsTienTrinhXuLy)
  yield takeEvery(hoSoBLTActions.getTongHanMuc.type, handleGetDsTongHanMuc)
  yield takeEvery(hoSoBLTActions.duyetHoSoBLT.type, handleDuyetNhieuHoSoBLT)
  yield takeEvery(hoSoBLTActions.guiDonThuoc.type, handleGuiDonThuocDanhSachHoSoBLT)
  yield takeEvery(hoSoBLTActions.dongBoDonThuoc.type, handleDongBoDonThuocHoSoBLT)
  yield takeEvery(hoSoBLTActions.getSoLuongDanhSach.type, handleGetSoLuongBanGhi)
  yield takeEvery(hoSoBLTActions.getDisplayTenThamDinhVien.type, handleDisplayThamDinhVien)
  yield takeEvery(hoSoBLTActions.xacNhanDoiSoat.type, handleXacNhanDoiSoat)
  yield takeEvery(hoSoBLTActions.printFilePdfToTrinh.type, handlePrintFileToTrinh)
}
