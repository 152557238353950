import {call, delay, put, takeEvery} from 'redux-saga/effects'
import {categoryActions} from './categorySlice'
import {
  getListInsuranceCompany,
  getListCSKCB,
  getListProvince,
  getListLyDoChuaThanhToan,
  getListDistrict,
  getListWard,
  getListLyDoTuChoi,
  getListLoaiFile,
  getDanhSachCauHinhDeNghiDuyetHoSo,
  getListTenCanBoDisplay,
  getListInsuranceCompanyWithFilter,
  getListCauHinhLoaiFileHD,
  getListDanhMucThuoc,
  getListCauHinhGiaoViec,
  getListSoHopDongBH,getListCauHinhDongChiTra,
  getListCskcbBaoLanh,
  getListLyDoPheDuyet,
  getListVaiTroThongTinLienHe,
  getListMaBenh,
} from './service'
import { handleError } from '_metronic/helpers'

function* debounceSearchGetListCauHinhDuyetHoSoBoiThuong(action) {
  yield call(handleGetCauHinhDuyetHoSoPaginate, {payload: action.payload})
};

function* debounceSearchInsuranceCompanyWithFilter(action) {
  yield delay(1500) // Đợi 300ms trước khi thực hiện tìm kiếm
  yield call(handleGetListInsuranceCompanyWithFilter, {payload: action.payload})
}

function* handleGetListCSKCB({payload}) {
  try {
    const resultsListCSKCB = yield call(getListCSKCB, payload)
    if (resultsListCSKCB?.result) {
      const arrayTemp = resultsListCSKCB?.result?.map((item) => ({
        label: `${item.value || ''} - ${item.label || ''}`,
        value: item.value,
      }))
      arrayTemp.unshift({
        label: 'Tất cả',
        value: null,
      })
      yield put({
        type: categoryActions.getListCSKCBSuccess.type,
        payload: {...resultsListCSKCB, results: arrayTemp},
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCSKCBFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListCSKCBNoLabelValue({payload}) {
  try {
    const result = yield call(getListCSKCB, payload)
    if (result) {
      yield put({
        type: categoryActions.getListCskcbNoLabelValueSuccess.type,
        payload: result,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCskcbNoLabelValuehFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListInsuranceCompany({payload}) {
  try {
    const resultsListInsuranceCompany = yield call(getListInsuranceCompany, payload)
    if (resultsListInsuranceCompany?.result) {
      const arrayTemp = resultsListInsuranceCompany.result
      yield put({
        type: categoryActions.getListInsuranceCompanySuccess.type,
        payload: {...resultsListInsuranceCompany, result: arrayTemp},
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListInsuranceCompanyFailed.type,
    })
  }
}
function* handleGetListProvince({payload}) {
  try {
    const resultListProvince = yield call(getListProvince, payload)
    if (resultListProvince) {
      const arrayTemp = resultListProvince.result
      // arrayTemp.unshift({
      //   label : "Tất cả",
      //   value : null
      // })
      yield put({
        type: categoryActions.getListProvinceSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListProvinceFailed.type,
    })
  }
}
function* handleGetListDistrict({payload}) {
  try {
    const resultListDistrict = yield call(getListDistrict, payload)
    if (resultListDistrict) {
      const arrayTemp = resultListDistrict.result
      // arrayTemp.unshift({
      //   label : "Tất cả",
      //   value : null
      // })
      yield put({
        type: categoryActions.getListDistrictSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListDistrictFailed.type,
    })
  }
}
function* handleGetListWard({payload}) {
  try {
    const resultListWard = yield call(getListWard, payload)
    if (resultListWard) {
      const arrayTemp = resultListWard.result
      yield put({
        type: categoryActions.getListWardSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListWardFailed.type,
    })
  }
}
function* handleGetListLyDoTuChoi({payload}) {
  try {
    const resultListLyDoTuChoi = yield call(getListLyDoTuChoi, payload)
    if (resultListLyDoTuChoi) {
      const arrayTemp = resultListLyDoTuChoi
      yield put({
        type: categoryActions.getListLyDoTuChoiSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLyDoTuChoiFailed.type,
    })
  }
}
function* handleGetListLyDoChuaThanhToan({payload}) {
  try {
    const result = yield call(getListLyDoChuaThanhToan, payload)
    if (result) {
      const arrayTemp = result
      yield put({
        type: categoryActions.getListLyDoChuaThanhToanSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLyDoChuaThanhToanFailed.type,
    })
  }
}
function* handleGetListLoaiFile({payload}) {
  try {
    const resultListLoaiFile = yield call(getListLoaiFile, payload)
    if (resultListLoaiFile) {
      const arrayTemp = resultListLoaiFile
      yield put({
        type: categoryActions.getListLoaiFileSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLoaiFileFailed.type,
    })
  }
}

function* handleGetCauHinhDuyetHoSoPaginate({payload}) {
  console.log(payload, 'payload')
  try {
    const resultListCauHinhDuyetHoSo = yield call(getDanhSachCauHinhDeNghiDuyetHoSo, payload)
    if (resultListCauHinhDuyetHoSo) {
      const arrayTemp = resultListCauHinhDuyetHoSo
      yield put({
        type: categoryActions.getListCauHinhDuyetHoSoSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCauHinhDuyetHoSoFailed.type,
    })
  }
}

function* handleGetListTenCanBO({payload}) {
  try {
    const resultsListTenCanBo = yield call(getListTenCanBoDisplay, payload)
    if (resultsListTenCanBo) {
      const arrayTemp = resultsListTenCanBo
      yield put({
        type: categoryActions.getListTenCanBoSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListTenc.type,
    })
    handleError(e)
  }}
  
function* handleGetListInsuranceCompanyWithFilter({ payload }) {
  try {
    const resultsListInsuranceCompanyWithFilter = yield call(getListInsuranceCompanyWithFilter, payload);
    if (resultsListInsuranceCompanyWithFilter) {
      const arrayTemp = resultsListInsuranceCompanyWithFilter
      yield put({
        type: categoryActions.getListInsuranceCompanyWithFilterSuccess.type,
        payload: { ...resultsListInsuranceCompanyWithFilter, result: arrayTemp },
      });
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListInsuranceCompanyWithFilterFailed.type,
    });
    handleError(e)
  }
}

function* handleGetListCauHinhLoaiFileHopDong({payload}) {
  try {
    const resultListLoaiFile = yield call(getListCauHinhLoaiFileHD, payload)
    if (resultListLoaiFile) {
      const arrayTemp = resultListLoaiFile
      yield put({
        type: categoryActions.getListCauHinhLoaiFileHDSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCauHinhLoaiFileHDFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListDanhMucThuoc({payload}) {
  try {
    const resultListDanhMucThuoc = yield call(getListDanhMucThuoc, payload)
    if (resultListDanhMucThuoc) {
      const arrayTemp = resultListDanhMucThuoc
      yield put({
        type: categoryActions.getListDanhMucThuocSuccess.type,
        payload: arrayTemp
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListDanhMucThuocFailed.type,
    });
    handleError(e)
  }
}

function* handleGetListCauHinhDongChiTra({payload}) {
  try {
    const resultListCauHinhDongChiTra = yield call(getListCauHinhDongChiTra, payload)
    if (resultListCauHinhDongChiTra) {
      const arrayTemp = resultListCauHinhDongChiTra
      yield put({
        type: categoryActions.getListCauHinhDongChiTraSuccess.type,
        payload: arrayTemp
      });
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListDanhMucThuocFailed.type,
    });
    handleError(e)
  }
}
function* handleGetListCauHinhGiaoViec ({ payload }) {
  try {
    const resultListCauHinhGiaoViec = yield call(getListCauHinhGiaoViec, payload);
    if (resultListCauHinhGiaoViec) {
      const arrayTemp = resultListCauHinhGiaoViec
      yield put({
        type: categoryActions.getListCauHinhGiaoViecSuccess.type,
        payload: arrayTemp
      });
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCauHinhGiaoViecFailed.type,
      })
    handleError(e)
  }
}

function* handleGetListSoHopDongBH ({ payload }) {
  try {
    const resultListSoHopDongBH = yield call(getListSoHopDongBH, payload);
    if (resultListSoHopDongBH) {
      const arrayTemp = resultListSoHopDongBH
      yield put({
        type: categoryActions.getListSoHopDongBHSuccess.type,
        payload: arrayTemp
      });
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListSoHopDongBHFailed.type,
    });
    handleError(e)
  }
}
function* handleGetListCskcbBaoLanh({payload}) {
  try {
    const resultListCskcbBaoLanh = yield call(getListCskcbBaoLanh, payload)
    if (resultListCskcbBaoLanh) {
      const arrayTemp = resultListCskcbBaoLanh
      yield put({
        type: categoryActions.getListCskcbBaoLanhSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListCskcbBaoLanhFailed.type,
    })
    handleError(e)
  }
}
function* handleGetListMaBenh({payload}) {
  try {
    const resultListMaBenh = yield call(getListMaBenh, payload)
    if (resultListMaBenh) {
      yield put({
        type: categoryActions.getListMaBenhSuccess.type,
        payload: resultListMaBenh,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListMaBenhFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListLyDoPheDuyet({payload}) {
  try {
    const resultListLyDoPheDuyet = yield call(getListLyDoPheDuyet, payload)
    if (resultListLyDoPheDuyet) {
      const arrayTemp = resultListLyDoPheDuyet
      yield put({
        type: categoryActions.getListLyDoPheDuyetSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListLyDoPheDuyetFailed.type,
    })
    handleError(e)
  }
}

function* handleGetListVaiTroThongTinLienHe({payload}) {
  try {
    const resultListLyDoPheDuyet = yield call(getListVaiTroThongTinLienHe, payload)
    if (resultListLyDoPheDuyet) {
      const arrayTemp = resultListLyDoPheDuyet
      yield put({
        type: categoryActions.getListVaiTroThongTinLienHeHopDongSuccess.type,
        payload: arrayTemp,
      })
    }
  } catch (e) {
    yield put({
      type: categoryActions.getListVaiTroThongTinLienHeHopDongFailed.type,
    })
    handleError(e)
  }
}
export default function* categorySaga() {
  yield takeEvery(categoryActions.getListLyDoChuaThanhToan.type, handleGetListLyDoChuaThanhToan)
  yield takeEvery(
    categoryActions.getListCauHinhDuyetHoSo.type,
    debounceSearchGetListCauHinhDuyetHoSoBoiThuong
  )
  yield takeEvery(categoryActions.getListTenCanBo.type, handleGetListTenCanBO)
  yield takeEvery(categoryActions.getListCSKCB.type, handleGetListCSKCB);
  yield takeEvery(categoryActions.getListInsuranceCompany.type, handleGetListInsuranceCompany);
  yield takeEvery(categoryActions.getListProvince.type, handleGetListProvince);
  yield takeEvery(categoryActions.getListDistrict.type, handleGetListDistrict);
  yield takeEvery(categoryActions.getListWard.type, handleGetListWard);
  yield takeEvery(categoryActions.getListLyDoTuChoi.type, handleGetListLyDoTuChoi);
  yield takeEvery(categoryActions.getListLoaiFile.type, handleGetListLoaiFile);
  yield takeEvery(categoryActions.getListInsuranceCompanyWithFilter.type, debounceSearchInsuranceCompanyWithFilter);
  yield takeEvery(categoryActions.getListCauHinhLoaiFileHD.type, handleGetListCauHinhLoaiFileHopDong);
  yield takeEvery(categoryActions.getListDanhMucThuoc.type, handleGetListDanhMucThuoc);
  yield takeEvery(categoryActions.getListCauHinhGiaoViec.type, handleGetListCauHinhGiaoViec);
  yield takeEvery(categoryActions.getListSoHopDongBH.type, handleGetListSoHopDongBH);
  yield takeEvery(categoryActions.getListCauHinhDongChiTra.type, handleGetListCauHinhDongChiTra);
  yield takeEvery(categoryActions.getListCSKCB.type, handleGetListCSKCB)
  yield takeEvery(categoryActions.getListInsuranceCompany.type, handleGetListInsuranceCompany)
  yield takeEvery(categoryActions.getListProvince.type, handleGetListProvince)
  yield takeEvery(categoryActions.getListDistrict.type, handleGetListDistrict)
  yield takeEvery(categoryActions.getListWard.type, handleGetListWard)
  yield takeEvery(categoryActions.getListLyDoTuChoi.type, handleGetListLyDoTuChoi)
  yield takeEvery(categoryActions.getListLoaiFile.type, handleGetListLoaiFile)
  yield takeEvery(
    categoryActions.getListCauHinhLoaiFileHD.type,
    handleGetListCauHinhLoaiFileHopDong
  )
  yield takeEvery(categoryActions.getListDanhMucThuoc.type, handleGetListDanhMucThuoc)
  yield takeEvery(categoryActions.getListCauHinhDongChiTra.type, handleGetListCauHinhDongChiTra)
  yield takeEvery(categoryActions.getListCskcbBaoLanh.type, handleGetListCskcbBaoLanh)
  yield takeEvery(categoryActions.getListLyDoPheDuyet.type, handleGetListLyDoPheDuyet)
  yield takeEvery(categoryActions.getListVaiTroThongTinLienHeHopDong.type, handleGetListVaiTroThongTinLienHe)
  yield takeEvery(categoryActions.getListMaBenh.type, handleGetListMaBenh)
  yield takeEvery(categoryActions.getListCskcbNoLabelValue.type, handleGetListCSKCBNoLabelValue)

}
