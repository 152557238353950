import {removeNullFields} from '_metronic/helpers'
import {api} from 'app/config/api'
import axios from 'axios'

export const getListCSKCB = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGOTY_CSKCB}/display`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListInsuranceCompany = () => {
  return new Promise((resolve, reject) => {
    axios

      .get(`${api.API_INSURANCE_COMPANY}/display`)
      .then((value) => {
        resolve(value?.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListProvince = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CATEGORY_PROVINCE + `/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListDistrict = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(api.API_CATEGORY_DISTRICT + `/display?idTinhThanh=${data?.idTinhThanh}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListWard = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        api.API_CATEGORY_WARD +
          `/display?idTinhThanh=${data?.idTinhThanh}&idQuanHuyen=${data?.idQuanHuyen}`
      )
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListLyDoTuChoi = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGOTY_LY_DO_TU_CHOI}/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListLyDoChuaThanhToan = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGOTY_LY_DO_CHUA_THANH_TOAN}/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListLoaiFile = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_TYPE_FILE}/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getDanhSachCauHinhDeNghiDuyetHoSo = (data) => {
  console.log(data, 'data')
  console.log(data, 'data')
  const {...otherData} = data
  const objectTemp = {
    ...otherData,
    page: otherData?.page / otherData?.items_per_page,
  }
  removeNullFields(objectTemp)
  const objString = '?' + new URLSearchParams(objectTemp).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_CAU_HINH_DUYET_HO_SO_BOI_THUONG_PAGINATE}` + objString)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListCauHinhLoaiFileHD = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_TYPE_FILE_CONTRACT}/display?idCongTyBaoHiem=${data?.value}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListTenCanBoDisplay = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CONFIGURED_PAYMENT_COMPANY_OFFICERS_DISPLAY}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListCauHinhGiaoViec = (data) => {
  console.log(data, 'cate cau hinh')
  const objString = '?' + new URLSearchParams(data).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_PAGINATE_GET_WORLOAD_BALANCER}${objString}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
export const getListCauHinhDongChiTra = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_TYPE_FILE_CONTRACT}/display?idCongTyBaoHiem=${data?.value}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListInsuranceCompanyWithFilter = (data) => {
  const result = {
    ...data, // Giữ các thuộc tính từ data
    items_per_page: 100, // Đặt items_per_page sau cùng để ghi đè giá trị từ data nếu có
  }
  removeNullFields(result)
  const objString = '?' + new URLSearchParams(result).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_INSURANCE_COMPANY}/display/paginated/filtered-active` + objString)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListDanhMucThuoc = (data) => {
  const result = {
    ...data, // Giữ các thuộc tính từ data
    page: data.page/ data.items_per_page,
  }
  removeNullFields(result)
  const objString = '?' + new URLSearchParams(result).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_MEDICINE_LIST}` + objString)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListSoHopDongBH = (data) => {
  let params = ''
  if (!data?.items_per_page && !data?.page) {
    params = ''
  } else {
    params = `?page=${data?.page}&items_per_page=${10000}&idDonViQuanLy=${data?.idDonViQuanLy}`
  }
  const objString = '?' + new URLSearchParams(params).toString()
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_DANH_SACH_SO_HOP_DONG_BH_FILTER_THEO_CONG_TY_BAO_HIEM}${objString}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListCskcbBaoLanh = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_CSKCB + '/cskcb-bao-lanh/count'}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListLyDoPheDuyet = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_LY_DO_PHE_DUYET}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListVaiTroThongTinLienHe = (data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_THONG_TIN_LIEN_HE}`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export const getListMaBenh = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api.API_CATEGORY_DISEASE_CODE}/display`)
      .then(({data}) => {
        resolve(data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
